import React, { useState } from "react";
import Datas from "../data/faq-event/faq-event.json";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/faqEvent.js";
import "./style.css";

function EnglishCenter() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Styles>
      {/* Faq & Event */}
      <section className="event-faq-area" style={{ display: 'flex', justifyContent: 'center' }}>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="faq-area" style={{ textAlign: 'center' }}>
                <div className="sec-title" style={{ background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)', width: '100%', borderRadius: 5 }}>
                  <h4 style={{ padding: 10, color: "white" }}>STANDARDIZED TESTS</h4>
                </div>
                <div className="faq-box">
                  {Datas.test?.map((faqData, i) => (
                    <div className="faq-item" key={i}>
                      <button
                        className={`accordion-button ${activeIndex === i ? "active" : ""}`}
                        onClick={() => handleAccordionClick(i)}
                      >
                        <div className="accordion-icon">
                          <i className="las la-plus"></i>
                        </div>
                        <p className="accordion-title">
                          {faqData.faqTitle}
                        </p>
                      </button>
                      <div
                        className={`accordion-content ${activeIndex === i ? "show" : ""}`}
                      >
                        <p style={{ textAlign: "justify" }} className="accordion-desc">
                          {faqData.faqDesc}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default EnglishCenter;
