
import mujtaba from '../../assets/images/mujtaba.png'
import zohaib from '../../assets/images/zohaib.png'
import fozan from '../../assets/images/fozan.png'
import hannan from '../../assets/images/hannan.png'
import hashir from '../../assets/images/hashir.png'
import yahya from '../../assets/images/yahya.png'
import sadat from '../../assets/images/saadat.png'
import sufyan from '../../assets/images/sufyan.png'
import mudassor from '../../assets/images/mudassor.jpg'
import usama from '../../assets/images/usama.jpg'
import iqra from '../../assets/images/Iqra.jpg'
import saira from '../../assets/images/Saira.jpg'
import jamal from '../../assets/images/Jamal.jpg'
import ieltsimg from '../../assets/images/ieltsimg.jpg'
export const testimonial ={
    secTitle: "Lets See What Our Valuable Students Think About Us. Their Testimonials.",
    backgroundImage: "vd-bg.jpg",
    }
   export const Datas = [
     {
       id: "1",
       testimonialTitle: "University of Prince Edward Island-Canada",
       testimonialDesc:
         "Thanks to SCI's professional team, my educational journey became a reality. Their support, guidance, and expertise are unmatched, helped me throughout the admission process at UPEI, all provided free of cost. I highly recommend them!",
       authorImg: fozan,
       authorName: "Fozan Tariq",
       authorTitle: "Developer",
     },
     {
       id: "2",
       testimonialTitle: "Georgian College - Canada",
       testimonialDesc:
         "My name is Yahya Afzal from Pakistan, and I recently had my visa approved to study Computer Programming at Georgian College. The SCI team has been tremendous, guiding and assisting me throughout the entire admission process. I am grateful for their support and highly recommend their professional services.",
       authorImg: yahya,
       authorName: "Yahya Afzal",
       authorTitle: "Programmer",
     },
     {
       id: "3",
       testimonialTitle: "Memorial University - Canada",
       testimonialDesc:
         "After completing my Honors degree in Mechanical Engineering, I aimed to further my education in Oil & Gas in Canada. Study Concerns International's professional team guided me through the application process, securing my admission to MASc - Oil & Gas Engineering at Memorial University. I highly recommend SCI for its true guidance and professional services at no cost.",
       authorImg: usama,
       authorName: "Usama Bin Adil",
       authorTitle: "Master in Oil & Gas Engineering",
     },
     {
       id: "4",
       testimonialTitle: "Memorial University - Canada",
       testimonialDesc:
         "As an Electrical Engineer seeking affordable education, SCI guided me to Memorial University. With their assistance, I secured acceptance for the MASc - Energy Systems Engineering program, and now successfully pursuing my academic journey. I highly recommend SCI for its free professional services and reputable academic partners.",
       authorImg: sufyan,
       authorName: "Abu Sufyan",
       authorTitle: "MASc - Energy Systems Engineering",
     },
     {
       id: "5",
       testimonialTitle: "Centennial College - Toronto",
       testimonialDesc:
         "My name is Muhammad Zohaib from Pakistan. I completed Grade 12th Pre-Engineering with an A grade and received my visa for the Electrical Technician Program at Centennial College. SCI’s professional team guided me through the entire admission process. I highly recommend their exceptional services.",
       authorImg: zohaib,
       authorName: "Muhammad Zohaib",
       authorTitle: "Electrical Technician",
     },
     {
       id: "6",
       testimonialTitle: "Centennial College - Toronto",
       testimonialDesc:
         "This is Muhammad Mujtaba. Thanks to Study Concerns International's exceptional team, my educational aspirations became a reality. Their unparalleled support, guidance, and expertise assisted me throughout the admission process at Centennial College, all provided free of charge. I highly recommend Study Concerns International - SCI!",
       authorImg: mujtaba,
       authorTitle: "Biotechnology",
       authorName: "Muhammad Mujtaba",
     },
     {
       id: "7",
       testimonialTitle: "St. Clair College - Canada",
       testimonialDesc:
         "My dream was to study in Canada after Grade 12. Numerous companies in Pakistan left me confused with fancy ads. Visiting SCI helped me find the right program aligned with my goals. I quickly received my acceptance letter and got my visa approved in a month. I highly recommend SCI’s free, professional services.",
       authorImg: hannan,
       authorTitle: "Computer Systems Technician",
       authorName: "Muhammad Hannan",
     },
     {
       id: "8",
       testimonialTitle: "Centennial College - Toronto",
       testimonialDesc:
         "My name is Hashir, and I am from Pakistan. After completing Grade 12, a friend recommended Study Concerns International (SCI) for their free professional services. SCI assisted me in applying to my desired program, Biotechnology, at Centennial College in Toronto. Thanks to their support, I received my visa in a timely manner. Thank you, SCI team!",
       authorImg: hashir,
       authorTitle: "Biotechnology",
       authorName: "Muhammad Hashir",
     },
     {
       id: "9",
       testimonialTitle: "Sheridan College - Canada",
       testimonialDesc:
         "I am Sadaat Mahmood. After my Bachelor's in Business and working as a Marketing Officer, I felt despondent about my career. A meeting with Mr. Hassan, CEO of SCI, revived my hope. Encouraged by him, I applied for an advanced program in Canada. Thanks to SCI, I was accepted and received my visa approval. Thank you, Mr. Hassan, for your invaluable assistance and unwavering support.",
       authorImg: sadat,
       authorTitle: "Postgraduate - International Business",
       authorName: "Sadaat Mahmood",
     },
     {
       id: "10",
       testimonialTitle: "Sun Moon University - South Korea",
       testimonialDesc:
         "My name is Iqra Shabbir, and I had completed my Master’s in English a while ago. Struggling to re-enter the mainstream, a friend recommended I visit SCI. There, I met with professionals who secured my spot in the Public Administration program at Sun Moon University. Thanks to SCI, the process was seamless. By the grace of Allah, my visa for Korea has been approved. Thank You, SCI Team!",
       authorImg: iqra,
       authorTitle: "Public Administration",
       authorName: "Iqra Shabbir",
     },
     {
       id: "11",
       testimonialTitle: "Sun Moon University - South Korea",
       testimonialDesc:
         "My name is Saira Shabbir. After completing my Bachelor of Science and Master of Education, I was unsure about my career path. SCI's strong reputation led me to their office, and I’m grateful I visited. They secured my spot in the Business Administration program at Sun Moon University in South Korea. The process was smooth, and by Allah's grace, my visa was approved. Thank you, SCI!",
       authorImg: saira,
       authorTitle: "Postgraduate - International Business",
       authorName: "Business Administration",
     },
     {
       id: "12",
       testimonialTitle: "Sun Moon University - South Korea",
       testimonialDesc:
         "My name is Jamal Sabar, and I had completed two years of a Bachelor of Arts degree. I was concerned about my future as it was challenging to secure admission to a Master's program. Thanks to the dedicated team at SCI, especially those who secured my admission, I was accepted into the Master of Public Administration at Sun Moon University, Korea, and received my visa approval within 15 day.",
       authorImg: jamal,
       authorTitle: "Public Administration",
       authorName: "Jama; Sabar",
     },
   ];
 export  const team = [
     {
       id: 1,
       testimonialTitle: "IELTS Specialist",
       testimonialDesc:
         "Mr. Mudassor holds a Master of Arts in English Linguistics and Literature, as well as a Master of Philosophy in English Linguistics. With extensive experience in Skills Development, IELTS, PTE, Duolingo, TOPEFL, and more, Mr. Mudassor plays a key role in preparing Study Concerns International's students for English tests, as well as admission and embassy interviews.",
       authorImg: mudassor,
       authorTitle: "Head of the English Preparation Academy",
       authorName: "Mudassor Majeede",
       subDesc:"We are very excited and hope for the visa success for Affan!",
       regard:"Have a great day.",
       sincerely:"Sincerely,"
     },
     {
       id: 1,
       testimonialTitle: "IELTS Specialist",
       testimonialDesc:
         `Hello Syed, ${`\n`} Thank you so much for all the updates!
         As soon as we will be able to see a deposit amount on the Davis University account, we will start the process of issuing the I20 form for Affan, this process usually takes just 2 business days.`,
       authorImg: ieltsimg,
       authorTitle: "IELTS Teacher",
       authorName: "Mudassor Majeede",
       subDesc:"We are very excited and hope for the visa success for Affan!",
       regard:"Have a great day.",
       sincerely:"Sincerely,"
     },
   ];

