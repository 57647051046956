import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Search from "./common/Search";
import Sidebar from "./common/Sidebar";
import StickyMenu from "./common/StickyMenu";
import MobileMenu from "./common/MobileMenu";
import { Styles } from "./styles/header.js";
import img1 from "../assets/images/img1.jpg";
import icef from "../assets/images/icef.png";
import ReactCountryFlag from "react-country-flag";
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  return (
    <Styles>
      {/* Topbar */}
      <section className="top-bar">
        <Container>
          <Row>
            <Col lg="6" md="5">
              <div className="bar-left">
                <ul className="list-unstyled list-inline">
                  <li className="list-inline-item">
                    <i className="las la-map-marker"></i>721 Block L, MA Johar Town Lahore, Pakistan
                  </li>
                  <li className="list-inline-item">
                    <Link to={process.env.PUBLIC_URL + "/faq"}>
                      Have Questions
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg="6" md="7">
              <div className="bar-right d-flex justify-content-end">
                <ul className="list-unstyled list-inline bar-social">
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={process.env.PUBLIC_URL + "/"}>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
                <ul className="list-unstyled list-inline bar-lang">
                  <li className="list-inline-item">
                    <Dropdown>
                      <Dropdown.Toggle as="a">
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/us.png"}
                          alt=""
                        />
                        English<i className="las la-angle-down"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu as="ul">
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/us.png"
                            }
                            alt=""
                          />{" "}
                          English
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/fra.png"
                            }
                            alt=""
                          />{" "}
                          French
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/ger.png"
                            }
                            alt=""
                          />{" "}
                          German
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/spa.png"
                            }
                            alt=""
                          />{" "}
                          Spanish
                        </Dropdown.Item>
                        <Dropdown.Item as="li">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/bra.png"
                            }
                            alt=""
                          />
                          Brazilian
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
                <ul className="list-unstyled list-inline bar-login">
                  <li className="list-inline-item">
                    <Link to={process.env.PUBLIC_URL + "/login"}>
                      <i className="las la-user"></i>Log In
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={process.env.PUBLIC_URL + "/registration"}>
                      <i className="las la-user-edit"></i>Register
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Logo Area */}
      <section className="logo-area" style={{marginTop:-30}}>
        <Container>
          <Row>
            <Col
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              md="3"
            >
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img src={img1} alt="" width={"70%"} height={50}/>
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="logo-contact-box d-flex justify-content-end">
                <div className="emcontact-box d-flex">
                  <div className="box-icon">
                    <i className="flaticon-phone-call"></i>
                  </div>
                  <div className="box-content">
                    <p>Call Us Now</p>
                    <span>+1 (905) 622 1221</span>
                  </div>
                </div>
                <div className="emcontact-box d-flex">
                  <div className="box-icon">
                    <i className="flaticon-envelope"></i>
                  </div>
                  <div className="box-content">
                    <p>Enquery Us</p>
                    <span>info@studyconcers.com</span>
                  </div>
                </div>
                <div className="apply-btn">
                  <Link to={process.env.PUBLIC_URL + "/registration"}>
                    <i className="las la-clipboard-list"></i>Apply Now
                  </Link>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: -7,
                  right: -160,
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <img
                  src={icef}
                  alt=""
                  width={85}
                  height={85}
                  style={{ borderRadius: 100 }}
                />
                <span>ICEF Accredited Agency</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Navbar */}
      <section className="main-menu">
        <Container>
          <Row>
            <Col md="12">
              <div className="main-menu-box">
                <div className="menu-box d-flex justify-content-between">
                  <ul className="nav menu-nav">
                    <li className="nav-item dropdown active">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Home
                        {/* <i className="las la-angle-down"></i> */}
                      </Link>
                      {/* <ul className="dropdown list-unstyled">
                        <li className="nav-item active">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/"}
                          >
                            Home Style 1
                          </Link>
                        </li>
                        <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                      </ul> */}
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Pages <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/about"}
                          >
                            About Us
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/gallery"}
                          >
                            Gallery
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/services"}
                          >
                            Services
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/meet-the-team"}
                          >
                            Meet the Team
                          </Link>
                        </li>
                      
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/login"}
                          >
                            Log In
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/franchise"}
                          >
                            Franchise Opportunities
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/registration"}
                          >
                            Registration
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/contact"}
                          >
                            Contact
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/faq"}
                          >
                            Faq
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/404"}
                          >
                            404
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/course-grid"}
                        data-toggle="dropdown"
                      >
                        Courses 
                        {/* <i className="las la-angle-down"></i> */}
                      </Link>
                      {/* <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/course-grid"}
                          >
                            Course Grid
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/course-list"}
                          >
                            Course List
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/course-details"}
                          >
                            Course Details
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/countries"}
                          >
                            Countries
                          </Link>
                        </li>
                    <li className="nav-item dropdown">
      <Link
        className="nav-link dropdown-toggle"
        to={process.env.PUBLIC_URL + "/"}
        data-toggle="dropdown"
      >
        Partner Institutions{" "}
        <i className="las la-angle-down"></i>
      </Link>
      <ul className="dropdown list-unstyled">
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor"}
          >
            <ReactCountryFlag countryCode="CA" svg style={{ marginRight: '8px' }} /> Canada
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="AU" svg style={{ marginRight: '8px' }} /> Australia
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="GB" svg style={{ marginRight: '8px' }} /> United Kingdom
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="US" svg style={{ marginRight: '8px' }} /> USA
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="HU" svg style={{ marginRight: '8px' }} /> Hungary
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="LT" svg style={{ marginRight: '8px' }} /> Lithuania
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="PL" svg style={{ marginRight: '8px' }} /> Poland
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="NZ" svg style={{ marginRight: '8px' }} /> New Zealand
          </Link>
        </li>
      </ul>
    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/ielts"}
                        data-toggle="dropdown"
                      >
                        English CENTER
                        {/* <i className="las la-angle-down"></i> */}
                      </Link>
                      {/* <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/events"}
                          >
                            Toronto
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/event-details"}
                          >
                            Lahore
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        SUCCESS STORIES <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/events"}
                          >
                            Success story 1
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/event-details"}
                          >
                            success story 2
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown">
            <ScrollLink
              to="faq-event"
              smooth={true}
              duration={ 1000}
              className="nav-link dropdown-toggle"
              style={{cursor:"pointer"}}
            >
              FAQS
            </ScrollLink>
          </li>
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Event <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/events"}
                          >
                            Events
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/event-details"}
                          >
                            Event Details
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Blog <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/blog-classic"}
                          >
                            Blog Classic
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/blog-grid"}
                          >
                            Blog Grid
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/blog-details"}
                          >
                            Blog Details
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                    {/* <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/"}
                        data-toggle="dropdown"
                      >
                        Shop <i className="las la-angle-down"></i>
                      </Link>
                      <ul className="dropdown list-unstyled">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/products"}
                          >
                            Products
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/product-details"}
                          >
                            Product Details
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={process.env.PUBLIC_URL + "/cart"}
                          >
                            Cart
                          </Link>
                        </li>
                      </ul>
                    </li> */}
                  </ul>
                  {/* <ul className="nav search-cart-bar">
                    <li className="nav-item search-box">
                      <Search />
                    </li>
                    <li className="nav-item cart-box">
                      <Link
                        to={process.env.PUBLIC_URL + "/cart"}
                        className="nav-link nav-cart"
                      >
                        <i className="las la-shopping-bag"></i>
                      </Link>
                    </li>
                    <li className="nav-item side-box">
                      <Sidebar />
                    </li>
                  </ul> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Sticky Menu */}
      <StickyMenu />

      {/* Mobile Menu */}
      <MobileMenu />
    </Styles>
  );
};

export default Header;
