import React, { Component } from 'react';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import AboutUs from '../../components/AboutUs';
import IconBox from '../../components/IconBox';
import TabBox from './../../components/TabBox';
import TestimonialSlider from '../../components/TestimonialSlider';
import FaqEvent from '../../components/FaqEvent';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from "./styles/ielts.js";
import HeroSlider from '../../components/HeroSlider.js';
import IeltsBody from '../../components/IeltsBody.js';
import EnglishCenter from '../../components/EnglishCenter.js';
class IeltsCenter extends Component {

    render() {
        return (
          <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper about-page">
              {/* Header 2 */}
              <HeaderTwo />
              <HeroSlider />
              <IeltsBody />
              {/* Breadcroumb */}
              <EnglishCenter />

              {/* <BreadcrumbBox title="About Us" /> */}

              {/* About Area */}

              {/* Footer 2 */}
              <FooterTwo />
            </div>
          </Styles>
        );
    }
}

export default IeltsCenter