import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";

class TabBox extends Component {
  render() {
    const { services } = this.props;

    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey={services ? "overview" : "why"}>
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey={services ? "overview" : "why"}>
                        <i className="las la-arrow-right"></i>{" "}
                        {services
                          ? "Services Overview"
                          : "Why Study Concerns Intl."}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={services ? "institution" : "company"}>
                        <i className="las la-arrow-right"></i>{" "}
                        {services
                          ? "Services For Institutions"
                          : "Company Profile"}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={services ? "students" : "mission"}>
                        <i className="las la-arrow-right"></i>{" "}
                        {services ? "Services For Students" : "Our Mission"}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={services ? "parents" : "vision"}>
                        <i className="las la-arrow-right"></i>{" "}
                        {services ? "Services For Parents" : "Our Vision"}
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="ranking">
                        <i className="las la-arrow-right"></i> Our Ranking
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="research">
                        <i className="las la-arrow-right"></i> Our Research
                      </Nav.Link>
                    </Nav.Item> */}
                    {!services && (
                      <>
                        <Nav.Item>
                          <Nav.Link eventKey="global">
                            <i className="las la-arrow-right"></i> Global
                            Presence
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="credentials">
                            <i className="las la-arrow-right"></i> Credentials
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    )}
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey={services ? "institution" : "why"}>
                      <h4 className="tab-title">
                        {services
                          ? "Services For Institutions"
                          : "Why Study Concerns International - SCI?"}
                      </h4>
                      {services ? (
                        <p className="tab-desc">
                          As our organization continues to expand, our
                          overarching goal is to integrate innovative ideas and
                          experiences to enhance the quality of service provided
                          to students, ultimately resulting in increased
                          enrollment in foreign educational institutions. All
                          our efforts are dedicated to addressing the needs,
                          values, and desires of our clients, fostering a sense
                          of comfort and trust in our services. This approach
                          helps students become more receptive to the
                          credibility and authority we have established in this
                          field of service.
                        </p>
                      ) : (
                        <p className="tab-desc">
                          At Study Concern International, we recognize the
                          pivotal role of selecting the right institution in
                          shaping a successful career. Leveraging our expertise
                          and years of experience, we specialize in meticulously
                          matching students with universities and colleges that
                          best align with their academic goals and aspirations.
                          Our methodical approach, coupled with our robust
                          network of academic institutions, ensures that
                          students receive the best guidance and representation
                          throughout the application process. We are committed
                          to providing seamless support, offering timely
                          follow-ups and friendly reminders to ensure a
                          hassle-free experience. As the gateway to endless
                          opportunities, we are deeply committed to the success
                          of our students, understanding that their achievements
                          are a direct reflection of our dedication.
                        </p>
                      )}
                      {!services ? (
                        <ul className="list-unstyled check-list">
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Tailored university and college matching services.
                          </li>
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Comprehensive support and guidance throughout the
                            application process.
                          </li>
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>{" "}
                            Strong representation and partnerships with leading
                            academic institutions.
                          </li>
                        </ul>
                      ) : (
                        <>
                          <h6 style={{ marginBottom: 3 }}>
                            Our institutional services include:
                          </h6>
                          <ul className="list-unstyled check-list">
                            <li>
                              <i
                                style={{
                                  // marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                  textAlign: "justify",
                                }}
                                className="fa fa-check"
                              ></i>
                              Proactive marketing of programs offered by foreign
                              universities. Personalized student counseling to
                              guide them in selecting suitable courses
                            </li>
                            <li>
                              <i
                                style={{
                                  // marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                  textAlign: "justify",
                                }}
                                className="fa fa-check"
                              ></i>
                              Providing prospective students with relevant
                              information on tuition fees, living costs, and
                              entry-level job procedures to assist them during
                              their stay abroad
                            </li>
                            <li>
                              <i
                                style={{
                                  marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                  textAlign: "justify",
                                }}
                                className="fa fa-check"
                              ></i>
                              Conducting active promotions or presentations at
                              local universities to highlight the benefits of
                              foreign education
                            </li>
                            <li>
                              <i
                                style={{
                                  // marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                  textAlign: "justify",
                                }}
                                className="fa fa-check"
                              ></i>
                              Building the image of foreign universities to
                              effectively encourage students interested in
                              studying abroad through counseling and
                              presentation sessions
                            </li>
                            <li>
                              <i
                                style={{
                                  marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                }}
                                className="fa fa-check"
                              ></i>
                              Extensive marketing links throughout Pakistan
                            </li>
                            <li>
                              <i
                                style={{
                                  marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                }}
                                className="fa fa-check"
                              ></i>
                              Comprehensive screening of admission documents to
                              streamline the process for institutions
                            </li>
                            <li>
                              <i
                                style={{
                                  marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                }}
                                className="fa fa-check"
                              ></i>
                              Travel and accommodation arrangements
                            </li>
                            <li>
                              <i
                                style={{
                                  // marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                  textAlign: "justify",
                                }}
                                className="fa fa-check"
                              ></i>
                              Providing updates to institutions on local
                              educational policies and socio-economic changes
                              impacting recruitment
                            </li>
                            <li>
                              <i
                                style={{
                                  // marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                  textAlign: "justify",
                                }}
                                className="fa fa-check"
                              ></i>
                              Arranging schedules of appointments and seminars
                              for visiting institution staff to conduct more
                              attractive promotions
                            </li>
                            <li>
                              <i
                                style={{
                                  // marginTop: -3,
                                  width: 30,
                                  height: 30,
                                  fontSize: 12,
                                  textAlign: "justify",
                                }}
                                className="fa fa-check"
                              ></i>
                              Serving as a recognized representative can
                              significantly reduce the cost of promoting foreign
                              universities abroad
                            </li>
                          </ul>
                        </>
                      )}
                    </Tab.Pane>
                    {services ? (
                      <Tab.Pane eventKey="overview">
                        <h4 className="tab-title">Services Overview</h4>
                        <p className="tab-desc">
                          At Study Concern International, we offer a
                          comprehensive range of services tailored to meet the
                          diverse needs of institutions, students, and parents.
                          Our commitment to excellence and personalized approach
                          ensures that each client receives the highest level of
                          service and support.
                        </p>
                        <h6>For Institutions:</h6>
                        <p className="tab-desc">
                          Proactive marketing of programs offered by foreign
                          universities. Personalized student counseling to
                          assist in course selection. Providing relevant
                          information on tuition, living costs, and entry-level
                          job procedures. Active promotions and presentations to
                          local university students. Building the image of
                          foreign universities through counseling and
                          presentations. Extensive marketing links throughout
                          Pakistan. Comprehensive screening of admission
                          documents. Regular updates on local educational
                          policies and socio-economic changes. Arranging
                          appointments and seminars with visiting institution
                          staff.
                        </p>
                        <h6>For Students</h6>
                        <p className="tab-desc">
                          Full information on colleges, courses, and countries
                          of choice. Personalized career counseling to identify
                          the most suitable course. Guidance through the
                          application process. Assistance in preparing for IELTS
                          or TOEFL. Obtaining confirmation letters from
                          colleges. Guidance in procuring education loans from
                          nationalized banks. Visa assistance. Arrangements for
                          travel and stay abroad. Orientation programs. Career
                          development guidance during and after the study abroad
                          experience. Assistance with insurance and foreign
                          exchange. Guidance in preparing applications for
                          admission. Assistance with Statements of Purpose
                          (SOPs) and essays.
                        </p>
                        <h6>For Parents:</h6>
                        <p className="tab-desc">
                          Regular updates on the progress of their child's
                          application. Assistance in understanding the education
                          system and requirements of the chosen country.
                          Guidance on supporting their child during the study
                          abroad experience. Assistance in dealing with any
                          challenges or issues that may arise.
                        </p>
                        <p className="tab-desc">
                          At Study Concern International, we are dedicated to
                          providing exceptional services to institutions,
                          students, and parents alike. Our goal is to ensure
                          that each client receives the guidance and support
                          they need to achieve their educational goals and
                          aspirations.
                        </p>
                      </Tab.Pane>
                    ) : (
                      <Tab.Pane eventKey="company">
                        <h4 className="tab-title">Company Profile</h4>
                        <p className="tab-desc">
                          Study Concerns International (SCI), headquartered in
                          Canada, stands as a beacon for aspiring students
                          seeking admissions to prestigious institutions
                          worldwide, with a specific focus on Canada, the UK,
                          Australia, and Europe. Since its inception, SCI has
                          cultivated a robust global network with branch offices
                          strategically positioned in various countries.
                        </p>
                        <h4 style={{ marginTop: -15 }} className="tab-title">
                          Mission and Vision
                        </h4>
                        <p style={{ marginTop: -15 }} className="tab-desc">
                          Our mission revolves around empowering students to
                          achieve their academic aspirations by facilitating
                          admissions to esteemed international institutions. SCI
                          envisions a future where education transcends borders,
                          creating opportunities for students globally.
                        </p>
                        <h4 style={{ marginTop: -15 }} className="tab-title">
                          Exceptional Track Record
                        </h4>
                        <p style={{ marginTop: -15 }} className="tab-desc">
                          SCI has garnered acclaim for its outstanding track
                          record, consistently facilitating admissions for a
                          multitude of students. The dedication, honesty, and
                          diligence of our staff have not only set high
                          standards but have also earned SCI sole representation
                          rights from several international institutions.
                        </p>
                        <h4 style={{ marginTop: -15 }} className="tab-title">
                          Global Recognition
                        </h4>
                        <p style={{ marginTop: -15 }} className="tab-desc">
                          Embodying accountability and responsibility, SCI has
                          gained the trust of numerous foreign institutions. Our
                          global recognition has led to exclusive partnerships
                          and invitations to collaborate through foreign tours,
                          workshops, and seminars.
                        </p>
                        <h4 style={{ marginTop: -15 }} className="tab-title">
                          Professional Team
                        </h4>
                        <p style={{ marginTop: -15 }} className="tab-desc">
                          Staffed with highly skilled and qualified
                          professionals, SCI ensures that students receive the
                          guidance they need to make informed decisions about
                          their academic journeys.
                        </p>
                        <h4 style={{ marginTop: -15 }} className="tab-title">
                          Collaborative Initiatives
                        </h4>
                        <p style={{ marginTop: -15 }} className="tab-desc">
                          SCI actively organizes workshops, seminars, and
                          international tours, fostering closer collaborations
                          with foreign academic institutions. These initiatives
                          not only enhance our understanding but also contribute
                          to a broader awareness of the quality education
                          available at our partner institutions.
                        </p>
                        <h4 style={{ marginTop: -15 }} className="tab-title">
                          Building Trust
                        </h4>
                        <p style={{ marginTop: -15 }} className="tab-desc">
                          The exemplary reputation of SCI has attracted the
                          attention of international colleges and universities,
                          further solidifying the trust placed in our services.
                          As a global entity, we remain committed to delivering
                          unparalleled service, ensuring a seamless educational
                          journey for all. At SCI, we believe in the
                          transformative power of education, and our commitment
                          is to be a reliable partner in every student's pursuit
                          of academic excellence.
                        </p>
                      </Tab.Pane>
                    )}
                    {services ? (
                      <Tab.Pane eventKey="students">
                        <h4 className="tab-title">Services For Students</h4>
                        <p className="tab-desc">
                          At Study Concerns International (SCI), our student
                          services are designed to provide comprehensive support
                          and guidance to students embarking on their
                          educational journey abroad. We offer personalized
                          assistance at every step of the process, from
                          selecting the right course and college to securing
                          visas and arranging travel. Our services include:
                        </p>
                        <ul className="list-unstyled check-list">
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Detailed information on colleges, courses, and
                            countries of choice
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Personalized career counseling to identify the most
                            suitable course
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Guidance in the student application process
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Preparation assistance for language proficiency
                            tests like IELTS or TOEFL
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Procurement of confirmation letters from colleges
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Assistance in obtaining education loans from
                            nationalized banks
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Travel and accommodation arrangements
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Orientation programs to ease the transition to a new
                            country
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Career development guidance during and after studies
                            abroad
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Assistance with insurance and foreign exchange
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Comprehensive guidance in the preparation of an
                            application for admission
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Guidance on Statement of Purpose (SOPs) and essays
                          </li>
                          <p className="tab-desc">
                            With our dedicated team of professionals, we aim to
                            ensure that each student receives the support they
                            need to succeed in their educational pursuits
                            abroad.
                          </p>
                        </ul>
                      </Tab.Pane>
                    ) : (
                      <Tab.Pane eventKey="mission">
                        <h4 className="tab-title">Our Mission</h4>
                        <p className="tab-desc">
                          At Study Concerns International (SCI), we deeply value
                          our students' time, resources, and aspirations as they
                          pursue education abroad. Committed to excellence, SCI
                          serves as a trusted guide, using our expertise to
                          steer students toward the right career paths. Our
                          mission is to empower students to attain
                          qualifications that make them highly desirable
                          candidates in local and international job markets.
                          With adept career counselors, we deliver services
                          aligning with students' expectations. Central to our
                          mission is building trust, and instilling confidence
                          in students as they pursue education in destinations
                          like Australia, New Zealand, China, the UK, Hungary,
                          Canada, and the US. By earning a reputation for trust,
                          SCI aims to provide efficient consultancy services,
                          facilitating students' educational goals. Through
                          unwavering dedication, SCI aims to be the driving
                          force propelling students toward a future filled with
                          exceptional educational opportunities and unparalleled
                          career prospects.
                        </p>
                        <ul className="list-unstyled check-list">
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Tailored educational guidance.
                          </li>
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Extensive knowledge of international educational
                            systems.
                          </li>
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Commitment to students' educational goals.
                          </li>
                        </ul>
                      </Tab.Pane>
                    )}
                    {services ? (
                      <Tab.Pane eventKey="parents">
                        <h4 className="tab-title">Services For Parents</h4>
                        <p className="tab-desc">
                          For parents, Study Concerns International (SCI) offers
                          a range of services to ensure they are well informed
                          and supported throughout their child's educational
                          journey abroad. We understand that sending a child to
                          study in a foreign country can be daunting, which is
                          why we provide the following services to parents:
                        </p>
                        <ul className="list-unstyled check-list">
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Detailed information about the educational system,
                            culture, and lifestyle of the destination country
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Assistance in understanding the application and
                            admission process
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Regular updates on the progress of their child's
                            application and admission status
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Guidance on financial planning and managing expenses
                            related to education abroad
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Support in ensuring their child's safety and
                            well-being while studying abroad
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Access to resources and information to address any
                            concerns or queries they may have
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Assistance in coordinating travel arrangements and
                            accommodation for visits to their child
                          </li>
                          <li>
                            <i
                              style={{
                                marginTop: -3,
                                width: 30,
                                height: 30,
                                fontSize: 12,
                              }}
                              className="fa fa-check"
                            ></i>
                            Continuous support and communication throughout
                            their child's study abroad experience
                          </li>

                          <p className="tab-desc">
                            By offering these services, SCI aims to provide
                            parents with peace of mind and confidence in their
                            decision to support their child's education abroad.
                          </p>
                        </ul>
                      </Tab.Pane>
                    ) : (
                      <Tab.Pane eventKey="vision">
                        <h4 className="tab-title">Our Vision</h4>
                        <p className="tab-desc">
                          At Study Concern International, we recognize the
                          critical importance of selecting the right institution
                          for a successful career launch. Leveraging our
                          expertise and extensive experience, we excel in
                          identifying the most suitable universities and
                          colleges for each student. Our systematic approach and
                          strong representation of academic institutions greatly
                          benefit our applicants, ensuring a smooth application
                          process with persistent follow-up and friendly
                          reminders. We are deeply invested in our students'
                          success, as their achievements are directly linked to
                          our own.
                        </p>
                        <ul className="list-unstyled check-list">
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Experienced directors providing sound guidance and
                            leadership in education.
                          </li>
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Dedicated counselors offer valuable insights and
                            support throughout the application process.
                          </li>
                          <li>
                            <i
                              style={{ marginTop: -5 }}
                              className="fa fa-check"
                            ></i>
                            Proven success in securing hassle-free admissions
                            and processing scholarships.
                          </li>
                          {/* <li>
                          <i className="fa fa-check"></i>We facilitate on-spot
                          interviews and admissions in Pakistan by international
                          officers from universities and colleges, providing
                          convenience and efficiency.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>We prioritize
                          personalized attention to each student, avoiding an
                          assembly line approach. Our counseling is tailored to
                          the individual needs and aspirations of students.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>We provide exhaustive
                          information resources, including a comprehensive
                          library, CDs, brochures, videotapes, and application
                          forms, ensuring students have access to valuable
                          materials to support their decision-making process.
                        </li> */}
                        </ul>
                        {/* <p className="tab-desc">
                        At Study Concern International, we are committed to
                        providing exceptional services, empowering students to
                        make informed choices, and embark on a successful
                        educational journey. We are dedicated to helping
                        students achieve their academic aspirations and turn
                        their dreams into reality.
                      </p> */}
                      </Tab.Pane>
                    )}

                    {/* <Tab.Pane eventKey="ranking">
                      <h4 className="tab-title">Our Ranking</h4>
                      <p className="tab-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum amet quo eius saepe et quis
                        necessitatibus hic natus facere a nisi fuga rem quas
                        molestias, eveniet minima molestiae. Lorem ipsum dolor,
                        sit amet consectetur adipisicing elit. Ea, recusandae?
                        Assumenda, error. Quam dicta iusto saepe. Odit minus
                        voluptas, fuga ipsum quia debitis totam, tempore
                        laudantium quasi dicta dolorem deleniti.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                      </ul>
                    </Tab.Pane> */}
                    {/* <Tab.Pane eventKey="research">
                      <h4 className="tab-title">Our Research</h4>
                      <p className="tab-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum amet quo eius saepe et quis
                        necessitatibus hic natus facere a nisi fuga rem quas
                        molestias, eveniet minima molestiae. Lorem ipsum dolor,
                        sit amet consectetur adipisicing elit. Ea, recusandae?
                        Assumenda, error. Quam dicta iusto saepe. Odit minus
                        voluptas, fuga ipsum quia debitis totam, tempore
                        laudantium quasi dicta dolorem deleniti.
                      </p>
                      <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Lorem ipsum dolor sit
                          amet, consectetur adipisicing elit. Voluptatum amet
                          quo eius saepe et quis necessitatibus hic natus
                          facere.
                        </li>
                      </ul>
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="global">
                      <h4 className="tab-title">Global Presence</h4>
                      <p className="tab-desc">
                        SCI maintains a robust presence in various countries
                        through a network of branch offices, trusted partners,
                        and local institutions. Our ultimate goal is to educate
                        communities by guiding students towards studying abroad
                        to achieve their academic aspirations.
                      </p>
                      {/* <ul className="list-unstyled check-list">
                        <li>
                          <i className="fa fa-check"></i>ICEF Accredited
                          Education Company (0809)
                        </li>
                        <li>
                          <i className="fa fa-check"></i>ICEF Canada Course
                          Graduate (CCG 00288)
                        </li>
                        <li>
                          <i className="fa fa-check"></i>ICEF - Trained Agent
                          Counselor (IATC 0948)
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Business Number
                          773842893
                        </li>
                        <li>
                          <i className="fa fa-check"></i>SCI’s Business
                          Registration Number In Pakistan is 0053241
                        </li>
                        <li>
                          <i className="fa fa-check"></i>SCI is a member of The
                          Lahore Chamber Of Commerce & Industry, in
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Pakistan (Number
                          897090)
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Study Concerns
                          International’s National Tax Number in Pakistan
                          (1360253-5)
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Uniagents Certified
                          Agent
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Member of AFEIC
                        </li>
                        <li>
                          <i className="fa fa-check"></i>Education Scenario
                          International Pakistan
                        </li>
                        <li>
                          <i className="fa fa-check"></i>All Pakistan
                          Association of Consultants
                        </li>
                      </ul> */}
                    </Tab.Pane>
                    <Tab.Pane eventKey="credentials">
                      <h4 className="tab-title">Credentials</h4>
                      {/* <p className="tab-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum amet quo eius saepe et quis
                        necessitatibus hic natus facere a nisi fuga rem quas
                        molestias, eveniet minima molestiae. Lorem ipsum dolor,
                        sit amet consectetur adipisicing elit. Ea, recusandae?
                        Assumenda, error. Quam dicta iusto saepe. Odit minus
                        voluptas, fuga ipsum quia debitis totam, tempore
                        laudantium quasi dicta dolorem deleniti.
                      </p> */}
                      <ul className="list-unstyled check-list">
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          ICEF Accredited Education Company (0809)
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          ICEF Canada Course Graduate (CCG 00288)
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          ICEF - Trained Agent Counselor (IATC 0948)
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          Business Number 773842893
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          SCI’s Business Registration Number In Pakistan is
                          0053241
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          SCI is a member of The Lahore Chamber Of Commerce &
                          Industry, in
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          Pakistan (Number 897090)
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          Study Concerns International’s National Tax Number in
                          Pakistan (1360253-5)
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          Uniagents Certified Agent
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          Member of AFEIC
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          Education Scenario International Pakistan
                        </li>
                        <li>
                          <i
                            style={{ marginTop: -5 }}
                            className="fa fa-check"
                          ></i>
                          All Pakistan Association of Consultants
                        </li>
                      </ul>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default TabBox;
