import React, { useState } from "react";
import Datas from "../data/faq-event/faq-event.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/faqEvent.js";
import "./style.css";
function FaqEvent() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Styles>
      {/* Faq & Event */}
      <section id="faq-event" className="event-faq-area">
        <Container>
          <Row>
            <Col md="6">
              <div className="event-area">
                <Row>
                  <Col md="12">
                    <div className="sec-title">
                      <h4>
                        Upcoming <span>Events</span>
                      </h4>
                    </div>
                  </Col>
                  <Col md="12">
                    {Datas.eventDataList.map((eventData, i) => (
                      <div className="event-box d-flex" key={i}>
                        <div className="event-date text-center">
                          <p>{eventData.eventDate}</p>
                        </div>
                        <div className="event-details">
                          <h6>
                            <Link to={eventData.eventLink}>
                              {eventData.eventTitle}
                            </Link>
                          </h6>
                          <ul className="list-unstyled list-inline">
                            <li className="list-inline-item">
                              <i className="las la-clock"></i>
                              {eventData.eventTime}
                            </li>
                            <li className="list-inline-item">
                              <i className="las la-map-marker"></i>
                              {eventData.eventLocation}
                            </li>
                          </ul>
                          <p>{eventData.eventdesc}</p>
                        </div>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md="6">
              <div className="faq-area">
                <div className="sec-title">
                  <h4>
                    Frequently Ask <span>Question</span>
                  </h4>
                </div>
                <div className="faq-box">
                  {Datas.faqDataList.map((faqData, i) => (
                    <div className="faq-item" key={i}>
                      <button
                        className={`accordion-button ${
                          activeIndex === i ? "active" : ""
                        }`}
                        onClick={() => handleAccordionClick(i)}
                      >
                        <div className="accordion-icon">
                          <i className="las la-plus"></i>
                        </div>
                        <p
                          style={{
                            paddingLeft: 45,
                            lineHeight: 1.5,
                          }}
                        >
                          {faqData.faqTitle}
                        </p>
                      </button>
                      <div
                        className={`accordion-content ${
                          activeIndex === i ? "show" : ""
                        }`}
                      >
                        <p style={{ textAlign: "justify" }}>
                          {faqData.faqDesc}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default FaqEvent;
