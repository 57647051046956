import img1 from '../../assets/images/1.jpg'
import img2 from '../../assets/images/2.jpg'
import img3 from '../../assets/images/3.jpg'
import img4 from '../../assets/images/4.jpg'
import img5 from '../../assets/images/5.jpg'
import img6 from '../../assets/images/6.jpg'
import img7 from '../../assets/images/7.jpg'
import img8 from '../../assets/images/8.jpg'
import img9 from '../../assets/images/9.jpg'
import img10 from '../../assets/images/10.jpg'
import img11 from '../../assets/images/11.jpg'
import img12 from '../../assets/images/12.jpg'
import img13 from '../../assets/images/13.png'
import img14 from '../../assets/images/14.jpg'
import img15 from '../../assets/images/15.png'
import img16 from '../../assets/images/16.jpg'
import img17 from '../../assets/images/17.jpg'
import img18 from '../../assets/images/18.png'
import img19 from '../../assets/images/19.jpg'
import img20 from '../../assets/images/20.jpg'
import img21 from '../../assets/images/21.jpg'
import img22 from '../../assets/images/22.jpg'
import img23 from '../../assets/images/23.jpg'
import img24 from '../../assets/images/24.jpg'
import img25 from '../../assets/images/25.jpg'
import img27 from '../../assets/images/27.png'
import img28 from '../../assets/images/28.png'
import img29 from '../../assets/images/29.png'
import img30 from '../../assets/images/30.png'
export const data = [
  {
    id: 1,
    personImage: img1,
    personName: "Sheridan College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 2,
    personImage: img2,
    personName: "Georgian College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 3,
    personImage: img3,
    personName: "Niagara University",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 4,
    personImage: img4,
    personName: "Huron University",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 5,
    personImage: img5,
    personName: "Centennial College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 6,
    personImage: img6,
    personName: "Justice Institute",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 7,
    personImage: img7,
    personName: "Fanshawe College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 8,
    personImage: img8,
    personName: "Laurier College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 9,
    personImage: img9,
    personName: "Memorial University",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 10,
    personImage: img10,
    personName: "Robertson College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 11,
    personImage: img11,
    personName: "Conestoga College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 12,
    personImage: img12,
    personName: "Niagara College Canada",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 13,
    personImage: img13,
    personName: "Nait College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 14,
    personImage: img14,
    personName: "RRC Polytech College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 15,
    personImage: img15,
    personName: "Seneca Polytechnic College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 16,
    personImage: img16,
    personName: "Sprott Shaw College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 17,
    personImage: img17,
    personName: "Trinity Western University",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 18,
    personImage: img18,
    personName: "Oxford Internation College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 19,
    personImage: img19,
    personName: "Cambrian College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 20,
    personImage: img20,
    personName: "ST Clair College",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 21,
    personImage: img21,
    personName: "University of Niagara Falls",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 22,
    personImage: img22,
    personName: "University Canada West",
    personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 23,
    personImage: img23,
    personName: "University of Regina",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 24,
    personImage: img24,
    personName: "University Of Prince Edward Island",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 25,
    personImage: img25,
    personName: "University of Calgray",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  // {
  //   id: 26,
  //   personImage: img27,
  //   personName: "Lithuania Business College",
  //   // personTitle: "Teacher",
  //   socialLinks: {
  //     facebook: "//www.facebook.com",
  //     twitter: "//www.twitter.com",
  //     linkedin: "//www.linkedin.com",
  //     youtube: "//www.youtube.com",
  //   },
  // },
  // {
  //   id: 27,
  //   personImage: img28,
  //   personName: "Pacific Link College",
  //   // personTitle: "Teacher",
  //   socialLinks: {
  //     facebook: "//www.facebook.com",
  //     twitter: "//www.twitter.com",
  //     linkedin: "//www.linkedin.com",
  //     youtube: "//www.youtube.com",
  //   },
  // },
  {
    id: 28,
    personImage: img29,
    personName: "UNB University of BrunsWick",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
  {
    id: 29,
    personImage: img30,
    personName: "Trent University",
    // personTitle: "Teacher",
    socialLinks: {
      facebook: "//www.facebook.com",
      twitter: "//www.twitter.com",
      linkedin: "//www.linkedin.com",
      youtube: "//www.youtube.com",
    },
  },
];