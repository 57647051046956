import React, { useState } from "react";
import Datas from "../data/about-us/about-us.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ModalVideo from "react-modal-video";
import CountUp from "react-countup";
import { Styles } from "./styles/aboutUs.js";
import "react-modal-video/css/modal-video.min.css";
import video from '../assets/video/video.mp4';
import videoThumbnail from '../assets/images/landingPage.jpg';

const AboutUs = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <Styles>
      {/* About Us */}
      <section className="about-us">
        <Container>
          <Row>
            <Col md="6">
              <div className="about-image">
                <img
                  src={
                    process.env.PUBLIC_URL + `/assets/images/${Datas.mainImage}`
                  }
                  className="main-img"
                  alt=""
                />
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/pattern.png"}
                  className="pattern-img"
                  alt=""
                />
                {/* <div
                  className="video-player"
                  style={{
                    backgroundImage: `url(${videoThumbnail})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }}
                >
                  <ModalVideo
                    channel="custom"
                    isOpen={isOpen}
                    url={video}                    
                    onClose={() => setIsOpen(false)}
                  />
                  <button onClick={openModal} className="play-button">
                    <i className="las la-play"></i>
                  </button>
                </div> */}
              </div>
            </Col>
            <Col md="6">
              <div className="about-content">
                <h6
                  style={{ fontWeight: "bold", textAlign: "justify" }}
                  className="about-title"
                >
                  {Datas.title}
                </h6>

                <p className="about-para">
                  {Datas.desc1}
                  <span>{Datas.desc2}</span>
                </p>
                <Row>
                  <Col sm="4">
                    <div className="counter-box box1 text-center">
                      <h3>
                        <CountUp end={4000} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Students Helped</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box2 text-center">
                      <h3>
                        <CountUp end={150} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p>Partner Institutions</p>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="counter-box box3 text-center">
                      <h3>
                        <CountUp end={5000} duration={5} delay={1.5} />
                        <i className="las la-plus"></i>
                      </h3>
                      <p style={{ marginLeft: -20 }}>Courses</p>
                    </div>
                  </Col>
                </Row>
                <Link
                  className="readmore-btn"
                  to={process.env.PUBLIC_URL + "/about"}
                >
                  Read More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default AboutUs;
