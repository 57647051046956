import React, { useEffect, useRef } from 'react';
import img1 from "../assets/images/s1.jpg";
import img2 from "../assets/images/s2.jpg";
import img3 from "../assets/images/s3.jpg";
import img4 from "../assets/images/s4.jpg";
import img5 from "../assets/images/s5.jpg";
import img6 from "../assets/images/s6.jpg";
import img7 from "../assets/images/s7.jpg";
import img8 from "../assets/images/s8.jpg";
import img9 from "../assets/images/s9.jpg";
import img10 from "../assets/images/s10.jpg";
import img11 from "../assets/images/s11.jpg";
import img12 from "../assets/images/s12.jpg";
import img13 from "../assets/images/s13.jpg";
import img14 from "../assets/images/s14.jpg";
import img15 from "../assets/images/s15.jpg";
import img16 from "../assets/images/s16.jpg";
import img17 from "../assets/images/s17.jpg";
import img18 from "../assets/images/s18.jpg";
import img19 from "../assets/images/s19.jpg";

import { Styles } from "./styles/imageSlider";
import "./style.css";
const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  
];

const ImageSlider = ({  }) => {
 

  return (
    <Styles>
   <div  className="slider-image-container" style={{marginTop:50, textAlign:"center", fontSize:20, fontWeight:"bold" }}>
   Website in under maintenance

      {/* <div style={{gap:12}} className="slider-track">
        {images.concat(images).map((src, index) => (
          <div className="slides" key={index}>
            <img src={src} alt={`Image ${index + 1}`} className="slider-images"/>
          </div>
        ))}
      </div> */}
    </div>
    </Styles>
  );
};

export default ImageSlider;


// import React, { useState, useEffect, useRef } from "react";
// import "./style.css";
// import img1 from "../assets/images/1.jpg";
// import img2 from "../assets/images/2.jpg";
// import img3 from "../assets/images/3.jpg";
// import img4 from "../assets/images/4.jpg";
// import img5 from "../assets/images/5.jpg";
// import { Styles } from "./styles/imageSlider";

// const images = [img1, img2, img3, img4, img5];

// const ImageSlider = () => {
//   const sliderRef = useRef(null);

//   useEffect(() => {
//     const slider = sliderRef.current;
//     let start = null;
//     let animate;

//     const step = (timestamp) => {
//       if (!start) start = timestamp;
//       const elapsed = timestamp - start;

//       // Move slider left by 1px every frame
//       slider.style.transform = `translateX(${-elapsed * 0.1}px)`;

//       // When the first image is completely out of view, reset transform
//       if (elapsed * 0.1 >= 200) {
//         start = timestamp;
//         slider.appendChild(slider.firstElementChild);
//         slider.style.transform = `translateX(0)`;
//       }

//       animate = requestAnimationFrame(step);
//     };

//     animate = requestAnimationFrame(step);

//     return () => cancelAnimationFrame(animate);
//   }, []);

//   return (
//     <Styles>
//       <div className="slider-container">
//         <div
//           style={{
//             justifyContent: "center",
//             display: "flex",
//             marginTop: 50,
//             gap: 10,
//           }}
//           className="slider"
//           ref={sliderRef}
//         >
//           {images.map((image, index) => (
//             <div className="slide" key={index}>
//               <img
//                 src={image}
//                 width={200}
//                 height={270}
//                 alt={`Slide ${index + 1}`}
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </Styles>
//   );
// };

// export default ImageSlider;

// import React, { useState, useEffect } from "react";
// import "./style.css";
// import img1 from "../assets/images/1.jpg";
// import img2 from "../assets/images/2.jpg";
// import img3 from "../assets/images/3.jpg";
// import img4 from "../assets/images/4.jpg";
// import img5 from "../assets/images/5.jpg";
// import {Styles} from './styles/imageSlider'
// const images = [
//   img1, // Replace with actual image paths
//   img2,
//   img3,
//   img4,
//   img5,
// ];

// const ImageSlider = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 2000); // Change image every 2 seconds
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <Styles>
//       <div style={{justifyContent:"center", display:"flex", marginTop:50, gap:10}} className="slider-container">
//         {
//             images.map((i)=>{
//                 return(
//                     <img width={200} height={270} src={i} />
//                 )
//             })
//         }
//       </div>
//     </Styles>
//   );
// };

// export default ImageSlider;
