import React, { Component } from "react";
import Datas from "../data/footer/footer.json";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "./common/BackToTop";
import { Styles } from "./styles/footerOne.js";

const Footer = () => {
  return (
    <Styles>
      {/* Footer Area */}
      <footer
        className="footer1"
        style={{
          backgroundImage: `url(assets/images/${
            process.env.PUBLIC_URL + Datas.backgroundImage
          })`,
        }}
      >
        <Container>
          <Row>
            <Col md="4">
              <div className="footer-logo-info">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/f-logo.png"}
                  alt=""
                  className="img-fluid"
                />
                <p>
                  Lorem ipsum dolor sit amet, consectet adipisicing elit. Saepe
                  porro neque a nam null quos. Adipisci eius unde magnam ad,
                  nisi voluptates.
                </p>
                <ul className="list-unstyled">
                  <li>
                    <i className="las la-map-marker"></i>Boyd Cres, Ajax, ON,
                    L1Z1X7 Canada
                  </li>
                  <li>
                    <i className="las la-envelope"></i>info@studyconcerns.com
                  </li>
                  <li>
                    <i className="las la-phone"></i>+1 (905) 622 1221
                  </li>
                </ul>
                <ul className="list-unstyled" style={{marginTop:15}}>
                  <li>
                    <i className="las la-map-marker"></i>721 Block L, MA Johar Town Lahore, Pakistan
                  </li>
                  <li>
                    <i className="las la-envelope"></i>admissions@studyconcerns.com
                  </li>
                  <li>
                    <i className="las la-phone"></i>+92 (322) 202 2121
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="4">
              <div className="f-links">
                <h5>Useful Links</h5>
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>General Info
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Help Center
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Our Services
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Online Support
                    </Link>
                  </li>
                </ul>
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>General Info
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Help Center
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Our Services
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <i className="las la-angle-right"></i>Online Support
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="4">
              <div className="f-post">
                <h5>Recent Post</h5>
                <div className="post-box d-flex">
                  <div className="post-img">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/blog-2.jpg"}
                      alt=""
                    />
                  </div>
                  <div className="post-content">
                    <Link to={process.env.PUBLIC_URL + "/blog-details"}>
                      Lorem ipsum dolor sit amet consectet adipisicing elit
                      com...
                    </Link>
                    <span>Mar 30, 2020</span>
                  </div>
                </div>
                <div className="post-box d-flex">
                  <div className="post-img">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/images/blog-3.jpg"}
                      alt=""
                    />
                  </div>
                  <div className="post-content">
                    <Link to={process.env.PUBLIC_URL + "/blog-details"}>
                      Lorem ipsum dolor sit amet consectet adipisicing elit
                      com...
                    </Link>
                    <span>Mar 30, 2020</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* Copyright Area */}
      <section className="copyright-area">
        <Container>
          <Row>
            <Col md="6">
              <div className="copy-text">
                <p>
                  Copyright &copy; 2020 | Developed by{" "}
                  <i className="las la-heart"></i> by{" "}
                  <a
                    href={process.env.PUBLIC_URL + "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Naeem Abbas
                  </a>
                </p>
              </div>
            </Col>
            <Col md="6" className="text-right">
              <ul className="social list-unstyled list-inline">
                <li className="list-inline-item">
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href={process.env.PUBLIC_URL + "/"}>
                    <i className="fab fa-dribbble"></i>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        {/* Back To Top */}
        <BackToTop />
      </section>
    </Styles>
  );
};

export default Footer;
