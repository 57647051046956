import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Styles } from "./styles/ielts.js";
import ielts1 from "../assets/images/ielts1.jpg";
import ielts2 from "../assets/images/ielts2.jpg";
import { colors } from "./common/element/elements.js";
import "./style.css"
const IeltsBody = () => {
  const menue = [
    { id: 1, title: "Full-time Course" },
    { id: 2, title: "Evening Course" },
    { id: 3, title: "Weekend Course" },
    { id: 4, title: "Online Course" },
  ];
  return (
    <Styles>
      <div className="study-concerns-container">
        {/* <h3 className="tab-title" style={{marginTop:15}}>Study Concerns International - SCI</h3> */}
        <h5 className="tab-title" style={{marginTop:30}}>
          Unlock Your Potential with Our Free 2-Month English Test Preparation
          Course!
        </h5>
        <p className="tab-desc" style={{ textAlign: "justify", color:colors.text2 }}>
          Study Concerns International (SCI) is offering an exceptional
          opportunity at our English Center for students eager to pursue
          advanced studies at our esteemed partner institutions worldwide. Our
          comprehensive 2-month English Test Preparation Course is designed to
          elevate your English proficiency and master the skills essential for
          excelling in recognized English exams like IELTS, TOEFL, and
          DUOLINGO—all at no cost to you.
        </p>
        <h4 className="tab-title">Why Join Our Program?</h4>
        <ul>
          <li className="tab-desc" style={{ textAlign: "justify", color:colors.text2  }}>
            <strong style={{color: colors.black1}}> Tailored Curriculum:</strong> Our program is crafted to
            improve your listening, reading, writing, and speaking skills.
          </li>
          <li className="tab-desc" style={{ textAlign: "justify", color:colors.text2  }}> 
            <strong style={{color: colors.black1}}>Expert Instructors:</strong> Learn from experienced
            professionals dedicated to your success.
          </li>
          <li className="tab-desc" style={{ textAlign: "justify", color:colors.text2  }}>
            <strong style={{color: colors.black1}}>Proven Strategies:</strong> Gain insights and techniques to
            achieve high scores on your English tests.
          </li>
          <li className="tab-desc" style={{ textAlign: "justify", color:colors.text2  }}>
            <strong style={{color: colors.black1}}>Global Opportunities:</strong> Enhance your chances of
            admission to prestigious international institutions.
          </li>
        </ul>
        <h4 className="tab-title">Enrollment Details</h4>
        <p className="tab-desc" style={{ textAlign: "justify", color:colors.text2  }}>
          To enroll in our free English program, contact us at{" "}
          <a href="mailto:info@studyconcerns.com">info@studyconcerns.com</a>{" "}
          with your contact details, and one of our team members will reach out
          to you.
        </p>
        <h4 className="tab-title">Enhance Your English Proficiency for a Brighter Future</h4>
        <p className="tab-desc" style={{ textAlign: "justify" , color:colors.text2 }}>
          Proficiency in spoken and written English is essential for studying
          abroad. Recognized English tests like IELTS, TOEFL, and DUOLINGO
          assess your ability to effectively communicate in English-speaking
          environments. Achieving a high score can significantly improve your
          chances of admission to top international institutions.
        </p>
        <h4>Standardized Tests</h4>
        <p className="tab-desc" style={{ textAlign: "justify", color:colors.text2  }}>
          Following are the authorized tests which you may need according to
          your program and university requirements:
        </p>
        {/* <ul>
          <li>IELTS (International English Language Testing System)</li>
          <li>TOEFL (Test of English as a Foreign Language)</li>
          <li>DUOLINGO English Test</li>
        </ul> */}
        <p className="tab-desc" style={{ textAlign: "justify", color:colors.text2  }}>
          Join us at SCI and embark on a transformative journey towards your
          academic and professional aspirations!
        </p>
      </div>
      {/* <section className="tab-section">

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBlock: 30,
          paddingInline: 400,
        }}
      >
        <span style={{ fontSize: 30, fontWeight: "bold", marginTop: 10 }}>
          Free IELTS Preparation Courses
        </span>
        <span style={{ fontSize: 50, fontWeight: "700", marginTop: 10 }}>
          Prepare With Experienced IELTS Examiners
        </span>
        <div style={{ display: "flex" }}>
          {menue.map((i) => {
            return (
              <div style={{}}>
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <span style={{ fontSize: 15 }}>{i?.title}</span>
                  <MdKeyboardArrowRight
                    style={{ marginRight: 10, paddingTop: 2 }}
                    size={25}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <img
          src={ielts1}
          width={500}
          height={350}
          style={{ marginBlock: 20 }}
        />
        <div>
          <p
            className="tab-desc"
            style={{ marginTop: 10, textAlign: "justify", color :colors.text2}}
          >
            Study Concerns International - SCI presents an outstanding
            opportunity at our IELTS Training Center, tailored for students
            eager to pursue advanced studies at our esteemed partner
            institutions worldwide. Unlock your potential with SCI's exceptional
            offer: a complimentary 2-month IELTS Preparation Course. Immerse
            yourself in our comprehensive program crafted to elevate your
            English proficiency and master the skills essential for excelling in
            the IELTS exam. Embark on a transformative journey towards your
            academic and professional aspirations with us, entirely free of
            charge. To enroll in the Free IELTS program, kindly complete the
            form with your contact details for one of our team members to reach
            out to you.
          </p>
          <p
            style={{ marginTop: 10, textAlign: "justify" }}
            className="tab-desc"
          >
            When studying abroad, proficiency in spoken and written English is
            essential. To evaluate your English skills thoroughly, you must take
            a recognized English test like the IELTS. Owned by the British
            Council, IDP: IELTS Australia, and Cambridge Assessment English,
            IELTS assesses your ability to listen, read, write, and speak
            effectively, particularly in English-speaking environments. A high
            IELTS score can greatly improve your chances of admission to
            prestigious international institutions.
          </p>
          <img
  src={ielts2}
  width={500}
  height={350}
  style={{ display: 'block', margin: '0 auto', marginTop: 20, marginBottom: 20 }}
/>
          <p
            style={{ marginTop: 10, textAlign: "justify" }}
            className="tab-desc"
          >
            Achieving a high IELTS score is crucial for university admission.
            SCI has been providing education services in 10 countries since
            2016, helping international students achieve their academic goals.
            Discover how we can help you achieve your best IELTS band score.
          </p>
          <p
            style={{ marginTop: 10, textAlign: "justify" }}
            className="tab-desc"
          >
            Our experienced faculty is committed to helping you succeed in your
            English language test. We offer small-sized IELTS coaching sessions
            to ensure personalized attention for every student. In our IELTS
            preparation classes, we provide study materials and conduct weekly
            mock tests. We also offer a flexible schedule with offline and
            online support. Classes are held from morning to afternoon, catering
            to students' preferences.
          </p>
          <h6 style={{ marginTop: 20 }}>
            The IELTS exam comprises four sections:
          </h6>
          <p className="tab-desc">Listening, Reading, Writing, and Speaking.</p>
          <h6 style={{ marginTop: 20 }}>Listening:</h6>
          <p className="tab-desc">
            In the Listening section, you will listen to an audio recording and
            answer questions. This section has four parts, each with 10
            questions, and lasts for 30 minutes.
          </p>
          <h6 style={{ marginTop: 20 }}>Reading:</h6>
          <p className="tab-desc">
            The Reading section consists of various question types, such as
            short answer questions, summary completion, and sentence completion,
            true or false, and multiple-choice questions. You will have 60
            minutes to complete 40 questions based on three texts, totaling
            approximately 2000-2500 words. The content of the texts varies
            between the Academic and General Training versions of the exam.
          </p>
          <h6 style={{ marginTop: 20 }}>Writing:</h6>
          <p className="tab-desc">
            Moving on to the Writing section, you will have 60 minutes to
            complete two tasks. Task 1 requires a minimum of 150 words, while
            Task 2 requires a minimum of 250 words. Tasks may include
            interpreting a chart or diagram, writing an essay, or composing a
            letter. The tasks differ between the Academic and General Training
            versions.
          </p>
          <h6 style={{ marginTop: 20 }}>Speaking:</h6>
          <p className="tab-desc">
            Lastly, the Speaking section consists of three parts. In Part 1, you
            will introduce yourself. In Part 2, you will speak for 1-2 minutes
            on a given topic. In Part 3, you will engage in a discussion with
            the examiner on topics related to Part 2.
          </p>
          <p
            className="tab-desc"
            style={{ marginTop: 20, fontWeight: "500", fontSize: 17 }}
          >
            To enroll in the Free IELTS program, kindly complete the form with
            your contact details for one of our team members to reach out to you
          </p>
        </div>
      </div>
      </section> */}
    </Styles>
  );
};

export default IeltsBody;
