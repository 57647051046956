import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";
import franchise from "../assets/images/franchise.jpg";
import "./style.css"
import { colors } from "./common/element/elements.js";


class Franchise extends Component {
  render() {
    const { services } = this.props;

    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container style={{ width: "50%", color: "black" }}>
          <img
              src={franchise}
              alt="Franchise"
              style={{ width: "100%", height: "auto", marginTop: 0, marginBottom: 30, borderRadius:10 }}
            />
            <h5 className="tab-title">Franchise Opportunities with Study Concerns International</h5>
            
            <p
              className="tab-desc"
              style={{ textAlign: "justify", fontSize: 14, marginTop: 10, color:colors.text2 }}
            >
              As our organization continues to expand, our overarching goal is
              to integrate innovative ideas and experiences to enhance the
              quality of service provided to students, ultimately resulting in
              increased enrollment in foreign educational institutions. All our
              efforts are dedicated to addressing the needs, values, and desires
              of our clients, fostering a sense of comfort and trust in our
              services. This approach helps students become more receptive to
              the credibility and authority we have established in this field of
              service.
            </p>
            <h6 className="tab-title" style={{ marginBlock: 10, color: "black" }}>Benefits Include:</h6>
            <ul className="list-unstyled check-list tab-desc" style={{color:colors.text2}}>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Access to a wide range of institutions worldwide
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Best commission structure
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Dedicated team support for franchisees
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Fast admission process
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Client ownership
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Opportunity to visit partner institutions worldwide
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Counseling of students
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Liaison with the University/college on behalf of the student
              </li>
            </ul>
            <p
              className="tab-desc"
              style={{ textAlign: "justify", marginTop: 10, color:colors.text2 }}
            >
              To become an associate, please download an application form and
              one of our team members will contact you shortly.
            </p>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default Franchise;
