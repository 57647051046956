import landingPage from "../../assets/images/landingPage.jpg"
import landingPage1 from "../../assets/images/landingPage2.jpg"
import landingPage3 from "../../assets/images/landingPage3.jpg"
export const Datas = [
    {
        id: 1,
        backgroundImage: landingPage,
        uniqClass: "slider-box slider-box1",
        title: "Welcome To SCI",
        desc: "Best University In This Region Join With Us Today",
        btnOneLink: "course-grid",
        btnTwoLink: "contact"
    },
    {
        id: 2,
        backgroundImage: landingPage1,
        uniqClass: "slider-box slider-box2 text-right",
        title: "Welcome To SCI",
        desc: "Best University In This Region Join With Us Today",
        btnOneLink: "course-grid",
        btnTwoLink: "contact"
    },
    {
        id: 3,
        backgroundImage: landingPage3,
        uniqClass: "slider-box slider-box2 text-right",
        title: "Welcome To SCI",
        desc: "Best University In This Region Join With Us Today",
        btnOneLink: "course-grid",
        btnTwoLink: "contact"
    }
]
