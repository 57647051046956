// src/DropdownSearch.js
import React, { useState } from "react";
import "./style.css"; // Import the CSS file

const courses = [
  "Master Programs",
"Postgraduate Certificates",
"Postgraduate Bundle Certificates",
"Bachelor Degrees",
"Associate Degrees",
"Advanced Diplomas",
"Diplomas"
];

const countries = [
  "Canada",
  "United Kingdom",
  "Australia",
  "USA",
  "Hungary",
  "Lithuania",
  "Poland",
  "New Zeland"
];

const DropdownSearch = () => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectStream, setSelectStream] = useState("");

  const handleSearch = () => {
    alert(`Searching for ${selectedCourse} courses in ${selectedCountry}`);
  };

  return (
    <div style={{paddingLeft:20,}}>
      <h1>Search Study Programs</h1>
      <div style={{justifyContent:"space-between"}} className="dropdown-search-container">
      <label style={{}}>
          {/* Select Country: */}
          <select
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <option value="">Select country</option>
            {countries.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        </label>
        <label>
          {/* Select Study Level: */}
          <select
            value={selectedCourse}
            onChange={(e) => setSelectedCourse(e.target.value)}
          >
            <option value="">Select course</option>
            {courses.map((course, index) => (
              <option key={index} value={course}>
                {course}
              </option>
            ))}
          </select>
        </label>
        <label>
          {/* Select Programs: */}
          <select
            value={selectStream}
            onChange={(e) => setSelectStream(e.target.value)}
          >
            <option value="">Select Programs:</option>
            {courses.map((course, index) => (
              <option key={index} value={course}>
                {course}
              </option>
            ))}
          </select>
        </label>
       
        <button style={{background: 'linear-gradient(90deg, #11B67A 0%, #009444 100%)'}} className="button" onClick={handleSearch}>Search</button>
      </div>
    </div>
  );
};

export default DropdownSearch;
