import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Styles } from "./styles/stickyMenu.js";
import img1 from "../../assets/images/img1.jpg";
import ReactCountryFlag from "react-country-flag";

function StickyMenu() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".sticky-menu");

      if (window.scrollY > 160) {
        stickyMenu.classList.add("sticky");
      } else {
        stickyMenu.classList.remove("sticky");
      }
    });
  });

  return (
    <Styles>
      {/* Sticky Menu */}
      <section className="sticky-menu">
        <Container>
          <Row>
            <Col md="3">
              <div className="logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                  <img
                    src={img1}
                    // style={{ marginTop: -60 }}
                    width={171}
                    height={"10%"}
                    alt=""
                  />
                </Link>
              </div>
            </Col>
            <Col md="9">
              <div className="menu-box d-flex justify-content-end">
                <ul className="nav menu-nav">
                  <li className="nav-item dropdown active">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Home
                      {/* <i className="las la-angle-down"></i> */}
                    </Link>
                    {/* <ul className="dropdown list-unstyled">
                                            <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                                            <li className="nav-item active"><Link className="nav-link" to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                                        </ul> */}
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Pages <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/about"}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/gallery"}
                        >
                          Gallery
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/services"}
                        >
                          Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/meet-the-team"}
                        >
                          Meet the Team
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/countries"}
                        >
                          Countries
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/login"}
                        >
                          Log In
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/franchise"}
                        >
                          Franchise Opportunities
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/registration"}
                        >
                          Registration
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/contact"}
                        >
                          Contact
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/faq"}
                        >
                          Faq
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/404"}
                        >
                          404
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                  <Link
                        className="nav-link dropdown-toggle"
                        to={process.env.PUBLIC_URL + "/course-grid"}
                        data-toggle="dropdown"
                      >
                        Courses 
                        {/* <i className="las la-angle-down"></i> */}
                      </Link>
                    {/* <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-grid"}
                        >
                          Course Grid
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-list"}
                        >
                          Course List
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/course-details"}
                        >
                          Course Details
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                  <li className="nav-item dropdown">
      <Link
        className="nav-link dropdown-toggle"
        to={process.env.PUBLIC_URL + "/"}
        data-toggle="dropdown"
      >
        Partner Institutions{" "}
        <i className="las la-angle-down"></i>
      </Link>
      <ul className="dropdown list-unstyled">
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor"}
          >
            <ReactCountryFlag countryCode="CA" svg style={{ marginRight: '8px' }} /> Canada
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="AU" svg style={{ marginRight: '8px' }} /> Australia
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="GB" svg style={{ marginRight: '8px' }} /> United Kingdom
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="US" svg style={{ marginRight: '8px' }} /> USA
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="HU" svg style={{ marginRight: '8px' }} /> Hungary
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="LT" svg style={{ marginRight: '8px' }} /> Lithuania
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="PL" svg style={{ marginRight: '8px' }} /> Poland
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            to={process.env.PUBLIC_URL + "/instructor-details"}
          >
            <ReactCountryFlag countryCode="NZ" svg style={{ marginRight: '8px' }} /> New Zealand
          </Link>
        </li>
      </ul>
    </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/ielts"}
                      data-toggle="dropdown"
                    >
                      English CENTER
                      {/* <i className="las la-angle-down"></i> */}
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    {/* <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Event <i className="las la-angle-down"></i>
                    </Link> */}
                    {/* <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/events"}
                        >
                          Events
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/event-details"}
                        >
                          Event Details
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Blog <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/blog-classic"}
                        >
                          Blog Classic
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/blog-grid"}
                        >
                          Blog Grid
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/blog-details"}
                        >
                          Blog Details
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to={process.env.PUBLIC_URL + "/"}
                      data-toggle="dropdown"
                    >
                      Shop <i className="las la-angle-down"></i>
                    </Link>
                    <ul className="dropdown list-unstyled">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/products"}
                        >
                          Products
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/product-details"}
                        >
                          Product Details
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to={process.env.PUBLIC_URL + "/cart"}
                        >
                          Cart
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
                <div className="apply-btn">
                  <Link to={process.env.PUBLIC_URL + "/registration"}>
                    <i className="las la-clipboard-list"></i>Apply Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default StickyMenu;
