import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/countryBox.js";

class CountryBox extends Component {
  render() {
    const { services } = this.props;

    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container>
            <Tab.Container defaultActiveKey={"ca"}>
              <Row>
                <Col lg="3" md="4">
                  <Nav className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey={"ca"}>
                        <i className="las la-arrow-right"></i> {"Canada"}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={"aus"}>
                        <i className="las la-arrow-right"></i> {"Australia"}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={"uk"}>
                        <i className="las la-arrow-right"></i>{" "}
                        {"United Kingdom"}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={"us"}>
                        <i className="las la-arrow-right"></i> {"USA"}
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="hu">
                        <i className="las la-arrow-right"></i> Hungary
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="pl">
                        <i className="las la-arrow-right"></i> Poland
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="nz">
                        <i className="las la-arrow-right"></i> New Zealand
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg="9" md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey={"ca"}>
                      <h4 className="tab-title">{"Canada"}</h4>

                      <p className="tab-desc">
                        A Premier Destination for Education and Living Canada is
                        a Wonderful Place to Live and Study: With stunning
                        natural landscapes, vibrant cities, and welcoming
                        communities, Canada offers an exceptional quality of
                        life for students worldwide.
                      </p>

                      <h6>Canadian Education Is World Class:</h6>
                      <p className="tab-desc">
                        Renowned for its high academic standards, innovative
                        teaching methods, and cutting-edge research, Canada
                        provides students with a top-notch education recognized
                        worldwide.
                      </p>
                      <h6>Canada Is Multicultural and Open to the World:</h6>
                      <p className="tab-desc">
                        Embracing diversity, Canada offers a welcoming
                        environment for international students to experience a
                        rich tapestry of cultures and traditions.
                      </p>
                      <h6>Canada Is Innovative:</h6>
                      <p className="tab-desc">
                        Known for its technological advancements and research
                        breakthroughs, Canada is a hub of innovation, providing
                        students with endless opportunities to learn and grow.
                      </p>
                      <h6>Canada Produces Leaders:</h6>
                      <p className="tab-desc">
                        With a focus on leadership development and critical
                        thinking, Canada nurtures future leaders in various
                        fields, empowering students to make a positive impact.
                      </p>
                      <h6>Canada Is a Highly Competitive Economy:</h6>
                      <p className="tab-desc">
                        Boasting one of the world's most stable economies,
                        Canada offers students a wealth of opportunities for
                        career advancement. Predicted to lead G-7 growth in the
                        next 50 years, Canada is a prime destination for those
                        seeking a prosperous future.
                      </p>
                      <h6>Canada's Collaborative Research and Development: </h6>
                      <p className="tab-desc">
                        A major player in collaborative research, Canada fosters
                        partnerships between academia, industry, and government
                        to drive innovation and address global challenges.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                        Canada boasts a diverse population of over 38 million
                        people, offering a rich cultural tapestry and a
                        welcoming environment for international students.
                      </p>
                      <h6>Government:</h6>
                      <p className="tab-desc">
                        Canada operates under a parliamentary democracy and a
                        constitutional monarchy, with the Prime Minister serving
                        as the head of government. Despite political changes,
                        Canada's government remains stable and secure, providing
                        a conducive environment for learning and growth.
                      </p>
                      <h6>Canada's Constitution:</h6>
                      <p className="tab-desc">
                        Canada's constitution outlines the framework for
                        governance, enshrining the rights and freedoms of
                        Canadians, and ensuring a democratic and inclusive
                        society.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                        Canada boasts a diverse population of over 38 million
                        people, offering a rich cultural tapestry and a
                        welcoming environment for international students.
                      </p>
                      <p className="tab-desc">
                        In conclusion, Canada's collaborative research, diverse
                        population, stable government, and commitment to
                        democracy make it an ideal destination for students
                        seeking a high-quality education and a vibrant cultural
                        experience.
                      </p>
                      <h6>Useful Links:</h6>
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <span>
                          <a
                            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada.html"
                            target="_blank"
                          >
                            The Citizenship and Immigration Canada, Study Permit
                          </a>
                        </span>
                        <span>
                          <a
                            href="https://www.cicic.ca/1374/obtain_an_academic_credential_assessment_for_general_purposes.canada"
                            target="_blank"
                          >
                            For Credential Evaluation, Assessment, and
                            Qualification Assessment Services
                          </a>
                        </span>
                        <span>
                          <a
                            href="https://caps-i.ca/resources/"
                            target="_blank"
                          >
                            The Canadian Association for Public
                            Schools—International (CAPS-I)
                          </a>
                        </span>
                        <span>
                          <a href="https://www.univcan.ca" target="_blank">
                            The Association of Universities and Colleges of
                            Canada (AUCC){" "}
                          </a>
                        </span>
                        <span>
                          <a href="www.collegeinstitutes.ca" target="_blank">
                            Colleges and Institutes Canada (CICan)
                          </a>
                        </span>
                        <span>
                          <a
                            href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/prepare/designated-learning-institutions-list.html"
                            target="_blank"
                          >
                            The Citizenship and Immigration Canada, Designated
                            Learning Institutions List
                          </a>
                        </span>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="hu">
                      <h4 className="tab-title">
                        Hungary, a Jewel in Central Europe
                      </h4>
                      <p className="tab-desc">
                        Hungary is a fantastic destination for international
                        students, offering high-quality education at affordable
                        tuition fees. With its modern and safe environment and
                        low cost of living, Hungary is an attractive choice. The
                        country's diverse and multicultural nature provides a
                        welcoming atmosphere for students from around the world.
                      </p>
                      <ul style={{}} className="">
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Capital: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Budapest
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Other major cities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Debrecen, Pécs, Szeged
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Population: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            9,944,000
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Currency:  {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Hungarian Forint (HUF)
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Number of universities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            14
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Number of study programs: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            303
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Ranked universities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            12
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Country Rank (2018): {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            11-20
                          </span>
                        </li>
                      </ul>
                      <h6 style={{ marginTop: 20 }}>Education System:</h6>
                      <p className="tab-desc">
                        Hungary boasts a well-established education system with
                        a rich history of academic excellence. It is home to
                        several prestigious universities and institutions
                        offering a wide range of programs in various fields.
                      </p>
                      <h6>Recognition:</h6>
                      <p className="tab-desc">
                        Degrees and qualifications from Hungarian institutions
                        are highly regarded worldwide, with many universities
                        ranked among the top in Europe. Hungary is known for its
                        high academic standards and innovative teaching methods.
                      </p>
                      <h6>Types of Institutions:</h6>
                      <p className="tab-desc">
                        Higher education institutions in Hungary include
                        universities, colleges, and specialized institutions.
                        Universities offer bachelors, masters, and doctoral
                        programs, while colleges focus more on practical,
                        vocational training.
                      </p>
                      <h6>Average Tuition Fee in Hungary:</h6>
                      <p className="tab-desc">
                        The average tuition fee in Hungary varies depending on
                        the institution and the program of study. Generally,
                        tuition fees for international students range from 1,500
                        to 5,000 euros per year for bachelor's programs and from
                        2,000 to 7,000 euros per year for master's programs.
                        However, tuition fees can be higher for certain programs
                        or institutions. It's advisable to check with the
                        specific university or college for the most up-to-date
                        information on tuition fees.
                      </p>
                      <h6>Types of Scholarships:</h6>
                      <p className="tab-desc">
                        In Hungary, several scholarships support international
                        students. The Stipendium Hungaricum is the main one,
                        fully funded by the government for all academic levels.
                        Universities also offer scholarships based on merit,
                        need, or specific criteria. The Hungarian Scholarship
                        Board (MAB) provides scholarships for foreign students,
                        researchers, and lecturers. Other options include the
                        Erasmus+ program for European study, plus research,
                        cultural exchange, and sports scholarships. Students
                        should explore these opportunities for study support in
                        Hungary.
                      </p>
                      <h6>Part-Time Work Opportunities:</h6>
                      <p className="tab-desc">
                        International students in Hungary are permitted to work
                        part-time during their studies, providing valuable work
                        experience and helping to supplement their living
                        expenses.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                        Hungary has a population of approximately 9.7 million
                        people, creating a diverse mix of ethnicities and
                        cultures that enrich the country's society.
                      </p>
                      <h6>Multiculturalism:</h6>
                      <p className="tab-desc">
                        Hungary has a rich multicultural history, with
                        influences from various cultures and traditions.
                        Budapest, the capital, is particularly diverse, offering
                        a vibrant and welcoming environment for international
                        students.
                      </p>
                      <h6>Languages:</h6>
                      <p className="tab-desc">
                        While Hungarian is the official language, many people in
                        urban areas, especially Budapest, speak English and
                        other foreign languages, making it easier for
                        international students to communicate and integrate into
                        Hungarian society.
                      </p>
                      <h6>Religion:</h6>
                      <p className="tab-desc">
                        Hungary is predominantly Christian, with Roman
                        Catholicism being the largest denomination. However, the
                        country is home to various religious communities,
                        reflecting its diverse religious landscape..
                      </p>
                      <h6>Climate:</h6>
                      <p className="tab-desc">
                        Hungary has a temperate continental climate, with hot
                        summers and cold winters. It experiences four distinct
                        seasons, with mild spring and autumn months.
                      </p>
                      <h6>Tourism:</h6>
                      <p className="tab-desc">
                        Hungary is a popular tourist destination, known for its
                        rich history, stunning architecture, and thermal spas.
                        Budapest, with its beautiful riverfront and historic
                        sites, attracts millions of visitors each year.
                      </p>
                      <h6>Land Size:</h6>
                      <p className="tab-desc">
                        With an area of approximately 93,000 square kilometers,
                        Hungary is one of the smallest countries in Europe.
                      </p>
                      <h6>Full-Time Job Opportunities:</h6>
                      <p className="tab-desc">
                        Hungary offers a range of job opportunities for
                        international students, particularly in sectors such as
                        tourism, IT, and finance, thanks to its growing economy
                        and favorable business environment.
                      </p>
                      <p style={{ marginTop: 10 }} className="tab-desc">
                        In summary, Hungary's rich cultural heritage,
                        high-quality education system, and welcoming society
                        make it an ideal destination for international students
                        seeking a unique and enriching academic experience.{" "}
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pl">
                      <h4 className="tab-title">Poland:</h4>
                      <p className="tab-desc">
                        Poland is an excellent destination for international
                        students, offering a blend of high-quality education,
                        vibrant culture, and affordability. Here's an overview
                        of Poland.
                      </p>
                      <ul style={{}} className="">
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Capital: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Warsaw
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Other major cities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Krakow, Wroclaw, Poznan, Gdansk
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Population: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Approximately 38 million
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Currency:  {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Polish Zloty (PLN)
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Number of universities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Over 400
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Number of study programs: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Over
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Ranked universities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Several Polish universities are ranked among the top
                            in the world for various disciplines.
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Country Rank (2018): {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            11-20
                          </span>
                        </li>
                      </ul>
                      <h6 style={{ marginTop: 20 }}>Education System:</h6>
                      <p className="tab-desc">
                        Poland has a well-established education system with a
                        strong focus on research and innovation. The country
                        offers a wide range of programs in various fields,
                        including humanities, sciences, engineering, and more.
                      </p>
                      <h6>
                        Average Tuition Fee at Public Universities in Poland:
                      </h6>
                      <p className="tab-desc">
                        The average tuition fees at public universities in
                        Poland are quite affordable compared to other European
                        countries. For EU/EEA students, education is free at
                        public universities for most programs, while non-EU/EEA
                        students can expect to pay an average of €2000 to €4000
                        per year for undergraduate programs and €3000 to €5000
                        per year for master's programs. Doctoral programs may
                        range from €2000 to €4000 per year.
                      </p>
                      <h6>Scholarship Opportunities:</h6>
                      <p className="tab-desc">
                        Poland offers various scholarship opportunities for
                        international students to help cover their tuition fees
                        and living expenses. These include government
                        scholarships such as the Polish National Agency for
                        Academic Exchange (NAWA) scholarships, which are
                        available for both full-degree and short-term study
                        programs. Additionally, many universities in Poland
                        offer their scholarships based on academic merit or
                        other criteria. International students are encouraged to
                        explore these options to help fund their studies in
                        Poland.
                      </p>
                      <h6>Recognition:</h6>
                      <p className="tab-desc">
                        Polish degrees are recognized worldwide for their
                        quality and are highly regarded by employers and
                        academic institutions.
                      </p>
                      <h6>Types of Institutions:</h6>
                      <p className="tab-desc">
                        Higher education institutions in Poland include
                        universities, technical universities, medical
                        universities, and academies of fine arts. These
                        institutions offer bachelors, masters, and doctoral
                        programs.
                      </p>
                      <h6>Part-Time Work Opportunities:</h6>
                      <p className="tab-desc">
                        International students in Poland are allowed to work
                        part-time while studying, providing an opportunity to
                        gain valuable work experience and supplement their
                        finances.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                        Poland is a diverse country with a population of
                        approximately 38 million, offering a mix of cultures and
                        traditions.
                      </p>
                      <h6>Multiculturalism:</h6>
                      <p className="tab-desc">
                        Poland has a rich multicultural history, with influences
                        from various European cultures. The country is known for
                        its warm hospitality and welcoming attitude towards
                        international students.
                      </p>
                      <h6>Languages:</h6>
                      <p className="tab-desc">
                        The official language of Poland is Polish, but many
                        universities offer programs in English, making it easier
                        for international students to study and live in the
                        country.
                      </p>
                      <h6>Religion:</h6>
                      <p className="tab-desc">
                        Poland is predominantly Roman Catholic, with the
                        majority of the population adhering to this faith.
                        However, the country is also home to other religious
                        communities.
                      </p>
                      <h6>Climate:</h6>
                      <p className="tab-desc">
                        Poland has a temperate climate with cold winters and
                        mild summers, offering a variety of weather conditions
                        throughout the year.
                      </p>
                      <h6>Tourism:</h6>
                      <p className="tab-desc">
                        Poland is a popular tourist destination, known for its
                        rich history, beautiful architecture, and cultural
                        attractions. Cities like Krakow and Warsaw attract
                        millions of visitors each year.
                      </p>
                      <h6>Land Size:</h6>
                      <p className="tab-desc">
                        Poland is the ninth-largest country in Europe, covering
                        an area of approximately 312,696 square kilometers.
                      </p>
                      <h6>Full-Time Job Opportunities:</h6>
                      <p className="tab-desc">
                        Poland offers a range of job opportunities for
                        international students, particularly in sectors such as
                        IT, engineering, finance, and healthcare. In summary,
                        Poland's rich cultural heritage, high-quality education
                        system, and welcoming environment make it an excellent
                        choice for international students seeking a unique and
                        rewarding study experience.
                      </p>
                      <p style={{ marginTop: 10 }} className="tab-desc">
                        In summary, Poland's rich cultural heritage,
                        high-quality education system, and welcoming environment
                        make it an excellent choice for international students
                        seeking a unique and rewarding study experience.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="nz">
                      <h4 className="tab-title">
                        New Zealand: A Land of Educational Excellence and
                        Natural Beauty
                      </h4>
                      {/* <p className="tab-desc">
                        Poland is an excellent destination for international
                        students, offering a blend of high-quality education,
                        vibrant culture, and affordability. Here's an overview
                        of Poland.
                      </p> */}
                      <ul style={{}} className="">
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Capital: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Wellington
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Other major cities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Auckland, Christchurch, Hamilton, Dunedin
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Population: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Approximately 5 million
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Currency:  {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            New Zealand Dollar (NZD)
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Number of universities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            8 universities
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Number of study programs: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Over 26,000 study programs
                          </span>
                        </li>
                        <li
                          style={{
                            listStyleType: "disc",
                            marginLeft: "1em",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          <i
                            style={{
                              marginTop: -5,
                            }}
                          ></i>{" "}
                          Ranked universities: {" "}
                          <span style={{ fontSize: 13, fontWeight: "normal" }}>
                            Several New Zealand universities are ranked among
                            the top universities globally, including the
                            University of Auckland, University of Otago, and
                            Victoria University of Wellington.
                          </span>
                        </li>
                      </ul>
                      <h6 style={{ marginTop: 20 }}>Study in New Zealand:</h6>
                      <p className="tab-desc">
                        Embarking on an educational journey in New Zealand
                        offers a distinctive opportunity to immerse oneself in a
                        diverse and multicultural society while receiving a
                        world-class education. The country's breathtaking
                        landscapes and inclusive culture make it an optimal
                        choice for international students seeking an enriching
                        experience.
                      </p>
                      <h6 style={{ marginTop: 20 }}>Education System:</h6>
                      <p className="tab-desc">
                        New Zealand boasts a prestigious education system
                        renowned for its emphasis on innovation, creativity, and
                        hands-on learning. The country is celebrated for its
                        high-quality education, with universities and
                        institutions offering a comprehensive array of programs
                        across various fields.
                      </p>
                      <h6>Recognition:</h6>
                      <p className="tab-desc">
                        Degrees and qualifications obtained from New Zealand
                        institutions are highly regarded globally, with many
                        universities consistently ranking among the top in the
                        world. New Zealand's commitment to academic excellence
                        and research-driven teaching is widely recognized.
                      </p>
                      <h6>Types of Institutions:</h6>
                      <p className="tab-desc">
                        Higher education institutions in New Zealand encompass
                        universities, polytechnics, and institutes of
                        technology. Universities provide bachelors, masters, and
                        doctoral programs, while polytechnics and institutes of
                        technology focus on vocational training and practical
                        learning.
                      </p>
                      <h6>
                        Average Tuition Fee at Public Universities in New
                        Zealand:
                      </h6>
                      <p className="tab-desc">
                        The average tuition fees at public universities in New
                        Zealand vary depending on the level of study and the
                        specific university. On average, undergraduate programs
                        for international students can range from NZD 22,000 to
                        NZD 32,000 per year. For postgraduate programs, the
                        average tuition fees can range from NZD 26,000 to NZD
                        37,000 per year. It's important to note that these are
                        approximate figures and tuition fees can vary.
                      </p>
                      <h6>Scholarship Opportunities in New Zealand:</h6>
                      <p className="tab-desc">
                        New Zealand offers a range of scholarships for
                        international students to help cover tuition fees,
                        living expenses, and other costs.
                      </p>
                      <h6>Part-Time Work Opportunities:</h6>
                      <p className="tab-desc">
                        International students in New Zealand are permitted to
                        engage in part-time work during their studies, offering
                        valuable work experience and additional financial
                        support.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                        With a population of approximately 5 million people, New
                        Zealand boasts a diverse populace, enriched by a blend
                        of ethnicities and cultures that contribute to its
                        vibrant societal fabric.
                      </p>
                      <h6>Multiculturalism:</h6>
                      <p className="tab-desc">
                        New Zealand is celebrated for its multiculturalism,
                        shaped by a history influenced by a variety of cultures
                        and traditions. The country's indigenous Maori culture
                        is a cornerstone of its identity, embraced and honored
                        nationwide.
                      </p>
                      <h6>Languages:</h6>
                      <p className="tab-desc">
                        English and Maori are the official languages of New
                        Zealand, with English being the predominant language
                        spoken. This linguistic environment facilitates seamless
                        communication and integration for international
                        students.
                      </p>

                      <h6>Climate:</h6>
                      <p className="tab-desc">
                        New Zealand features a temperate climate characterized
                        by mild temperatures and moderate rainfall year-round.
                        The country experiences four distinct seasons, with
                        summer from December to February and winter from June to
                        August.
                      </p>
                      <h6>Tourism:</h6>
                      <p className="tab-desc">
                        Renowned for its stunning landscapes, outdoor
                        activities, and adventure sports, New Zealand is a
                        favored tourist destination. From the pristine beaches
                        of the North Island to the majestic mountains of the
                        South Island, the country offers a plethora of
                        experiences for visitors.
                      </p>
                      <h6>Land Size:</h6>
                      <p className="tab-desc">
                        Covering an area of approximately 268,000 square
                        kilometers, New Zealand is relatively small in size.
                        Nevertheless, it boasts a diverse range of landscapes,
                        including mountains, forests, beaches, and fjords.
                      </p>

                      <p style={{ marginTop: 10 }} className="tab-desc">
                        In conclusion, New Zealand's blend of exceptional
                        education, breathtaking natural beauty, and inclusive
                        culture makes it an ideal destination for international
                        students seeking a distinctive and rewarding academic
                        experience.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="aus">
                      <h4 className="tab-title">
                      Australia: A Land of Diversity and Opportunity
                      </h4>
                      <h6 style={{ marginTop: 20 }}>Tertiary Education:</h6>
                      <p className="tab-desc">
                      Tertiary education in Australia encompasses universities, vocational education, and training (VET) institutions, and English language colleges. These institutions provide a wide range of qualifications, from certificates and diplomas to undergraduate and postgraduate degrees.
                      </p>
                      <h6 style={{ marginTop: 20 }}>Education System:</h6>
                      <p className="tab-desc">
                      Australia boasts a world-renowned education system that is globally recognized for its high standards and innovative teaching methods. With a strong emphasis on practical learning and research, Australian universities offer a diverse range of programs and courses to cater to the needs of students from around the world.
                      </p>
                      <h6>Language of Instruction:</h6>
                      <p className="tab-desc">
                      English is the primary language of instruction in Australian educational institutions. However, many universities and colleges also offer support services for students whose first language is not English, ensuring they can fully participate in their studies.
                      </p>
                      <h6>Australian Qualifications Framework:</h6>
                      <p className="tab-desc">
                      The Australian Qualifications Framework (AQF) is a national system that ensures the quality and consistency of qualifications across different education and training sectors. It provides a clear pathway for students to progress from one qualification level to the next.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                      Australia is a multicultural country with a rich diversity of cultures, languages, and traditions. Approximately 25% of Australians were born in another country, and the country is home to people from over 140 different nationalities.
                      </p>
                      <h6>Multiculturalism:</h6>
                      <p className="tab-desc">
                      Australia prides itself on its multicultural society, which is reflected in its diverse population. The country's multicultural policies promote social cohesion, cultural diversity, and the equal rights of all Australians, regardless of their background.
                      </p>
                      <h6>Languages:</h6>
                      <p className="tab-desc">
                      While English is the official language of Australia, the country is home to a variety of indigenous languages and dialects spoken by Aboriginal and Torres Strait Islander communities.
                      </p>

                      <h6>Religion:</h6>
                      <p className="tab-desc">
                      Australia is a religiously diverse country, with Christianity being the most widely practiced religion. However, the country is also home to a significant number of people practicing other religions, including Islam, Buddhism, Hinduism, and Sikhism.
                      </p>
                      <h6>Climate:</h6>
                      <p className="tab-desc">
                      Australia experiences a wide range of climates, from tropical in the north to temperate in the south. The country is known for its sunny weather, with most regions experiencing warm summers and mild winters.
                      </p>
                      <h6>Literacy Rate:</h6>
                      <p className="tab-desc">
                      Australia boasts a high literacy rate, with almost all Australians having basic literacy skills. The country places a strong emphasis on education, which is reflected in its high literacy levels among the population.
                      </p>
                      <h6>Total States:</h6>
                      <p className="tab-desc">
                      Australia is divided into six states and two territories. The states are New South Wales, Victoria, Queensland, South Australia, Western Australia, and Tasmania, while the territories are the Australian Capital Territory (ACT) and the Northern Territory.
                      </p>

                      <p style={{ marginTop: 10 }} className="tab-desc">
                      Australia's diverse culture, world-class education system, and welcoming environment make it an ideal destination for students seeking an enriching academic and cultural experience.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="uk">
                      <h4 className="tab-title">
                      United Kingdom: A Hub of Education, Culture, and Tourism
                      </h4>
                      <h6 style={{ marginTop: 20 }}>Tertiary Education:</h6>
                      <p className="tab-desc">
                      Tertiary education in the UK comprises universities, colleges, and institutions offering vocational and professional courses. The UK is home to a diverse range of institutions, each with its own unique strengths and areas of expertise.
                      </p>
                      <h6 style={{ marginTop: 20 }}>Education System:</h6>
                      <p className="tab-desc">
                      The United Kingdom is renowned for its prestigious education system, which includes some of the world's oldest and most respected universities. The UK offers a wide range of courses and programs, from undergraduate degrees to postgraduate research opportunities, attracting students from all corners of the globe.
                      </p>
                      <h6>Language of Instruction:</h6>
                      <p className="tab-desc">
                      English is the primary language of instruction in the UK's educational institutions. International students may be required to demonstrate their proficiency in English through tests such as the IELTS or TOEFL.
                      </p>
                      <h6>UK Qualifications Framework:</h6>
                      <p className="tab-desc">
                      The UK has a comprehensive qualifications framework that ensures the quality and standardization of qualifications across different education sectors. The framework provides clear pathways for students to progress from one level of qualification to another.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                      The UK is a culturally diverse country with a population that reflects its rich history of immigration. The country's population is made up of people from various ethnic, cultural, and religious backgrounds, creating a vibrant and multicultural society.
                      </p>
                      <h6>Multiculturalism:</h6>
                      <p className="tab-desc">
                      The UK prides itself on its multicultural heritage, which is evident in its diverse communities, cultural events, and cuisine. The country's multicultural policies promote social cohesion and celebrate the contributions of people from all walks of life.
                      </p>
                      <h6>Languages:</h6>
                      <p className="tab-desc">
                      In addition to English, the UK is home to several indigenous languages, including Welsh, Scottish Gaelic, and Irish. These languages are protected and promoted alongside English, reflecting the UK's commitment to its cultural diversity.
                      </p>

                      <h6>Religion:</h6>
                      <p className="tab-desc">
                      The UK is a predominantly Christian country, with the Church of England being the established church. However, the UK is also home to a significant number of people practicing other religions, including Islam, Hinduism, Sikhism, and Judaism.
                      </p>
                      <h6>Climate:</h6>
                      <p className="tab-desc">
                      The UK has a temperate maritime climate, with mild summers and cool winters. The weather can be changeable throughout the year, with rainfall occurring regularly in most regions.
                      </p>
                      <h6>Literacy Rate:</h6>
                      <p className="tab-desc">
                      The UK boasts a high literacy rate, with nearly all adults having basic literacy skills. The country places a strong emphasis on education and lifelong learning, which is reflected in its high literacy levels among the population.
                      </p>
                      <h6>Tourism:</h6>
                      <p className="tab-desc">
                      The UK is a popular tourist destination, attracting millions of visitors each year. From historic landmarks such as Buckingham Palace and the Tower of London to natural wonders like the Lake District and the Scottish Highlands, the UK offers a wealth of attractions for visitors to explore.
                      </p>
                      <h6>Capital:</h6>
                      <p className="tab-desc">
                      The capital of the United Kingdom is London, one of the world's most iconic cities. London is known for its rich history, vibrant culture, and diverse population, making it a truly global city.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="us">
                      <h4 className="tab-title">
                      United States of America: A Land of Opportunities and Diversity
                      </h4>
                      
                      <h6 style={{ marginTop: 20 }}>Education System:</h6>
                      <p className="tab-desc">
                      The United States boasts a diverse and renowned education system, with thousands of colleges, universities, and institutions offering a wide range of programs and courses. From Ivy League universities to community colleges, the US offers opportunities for students at every level.
                      </p>
                      <h6 style={{ marginTop: 20 }}>Recognition:</h6>
                      <p className="tab-desc">
                      US degrees and qualifications are highly regarded worldwide, with many institutions ranking among the top in various fields. The US is known for its emphasis on innovation, research, and academic excellence, making it a popular destination for international students seeking quality education.
                      </p>
                      <h6>Types of Institutions:</h6>
                      <p className="tab-desc">
                      In the US, higher education institutions include universities, colleges, and community colleges. Universities typically offer undergraduate, graduate, and doctoral programs, while colleges focus more on undergraduate degrees. Community colleges provide two-year associate degrees and certificates.
                      </p>
                      <h6>Part-Time Work Opportunities:</h6>
                      <p className="tab-desc">
                      International students studying in the US on an F-1 visa are generally allowed to work part-time on campus during their studies. They may also be eligible for certain off-campus work opportunities under specific circumstances, such as economic hardship or internships related to their field of study.
                      </p>
                      <h6>Population:</h6>
                      <p className="tab-desc">
                      The US is the third most populous country in the world, with a population that reflects its rich cultural diversity. The country is home to people from all over the world, contributing to its vibrant and multicultural society.
                      </p>
                      <h6>Multiculturalism:</h6>
                      <p className="tab-desc">
                      The US is known for its multiculturalism, with a rich tapestry of cultures, languages, and traditions. This diversity is celebrated and embraced across the country, making it a welcoming destination for international students.
                      </p>
                      <h6>Languages:</h6>
                      <p className="tab-desc">
                      While English is the primary language spoken in the US, the country is also home to a wide range of other languages spoken by various ethnic and cultural groups.
                      </p>

                      <h6>Religion:</h6>
                      <p className="tab-desc">
                      The US is a diverse country with a wide range of religious beliefs and practices. The country's constitution guarantees freedom of religion, allowing individuals to practice their faith freely.
                      </p>
                      <h6>Climate:</h6>
                      <p className="tab-desc">
                      The US has a diverse climate, ranging from tropical in Hawaii and Florida to arctic in Alaska. The country experiences four distinct seasons in most regions, with varying weather patterns depending on the location.
                      </p>
                      <h6>Tourism:</h6>
                      <p className="tab-desc">
                      The US is a popular tourist destination, offering a wide range of attractions and experiences. From vibrant cities like New York and Los Angeles to natural wonders like the Grand Canyon and Yellowstone National Park, the US has something for everyone.
                      </p>
                      <h6>Land Size:</h6>
                      <p className="tab-desc">
                      The United States is the fourth largest country in the world by land area, covering approximately 9.8 million square kilometers.
                      </p>
                      <h6>Job Opportunities:</h6>
                      <p className="tab-desc">
                      The US offers a wide range of job opportunities across various industries, including technology, healthcare, finance, and education. The country's strong economy and diverse business environment make it an attractive destination for job seekers.
                      </p>
                      <h6>Average Tuition Fee at Universities:</h6>
                      <p className="tab-desc">
                      The average tuition fee at universities in the US varies widely depending on the institution and the program of study. On average, tuition fees for undergraduate programs range from $20,000 to $50,000 per year, while graduate programs can cost anywhere from $30,000 to $70,000 per year. However, it's important to note that these are average figures and actual tuition fees may vary.                     
                      </p>
                      <h6>Total States and Their Names:</h6>
                      <p className="tab-desc">
                      The US comprises 50 states, each with its own unique culture, history, and attractions. Some of the most well-known states include California, Texas, New York, Florida, and Illinois. </p>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default CountryBox;
