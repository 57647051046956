import React, { Component } from 'react';
import Datas from '../../data/instructor/instructor.js';
import { data } from '../../data/instructor/instructor.js';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import Pagination from './../../components/Pagination';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/instructor.js';
import sheridan from '../../assets/images/sheridan.png'
import { colors } from '../../components/common/element/elements.js';
class Instructor extends Component {
    render() {
        return (
          <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper instructor-page">
              {/* Header 2 */}
              <HeaderTwo />

              {/* Breadcroumb */}
              <BreadcrumbBox title="Instructors" />

              {/* Instructor Area */}
              <section
                style={{ backgroundColor: colors.bg2 }}
                className="instructor-area"
              >
                <Container>
                  <Row>
                    {data.map((data, i) => (
                      <Col lg="3" md="4" sm="6" key={i}>
                        <div className="instructor-item">
                          <Link
                            to={process.env.PUBLIC_URL + "/instructor-details"}
                          >
                            <img src={data?.personImage} alt="" className="img-fluid" />
                          </Link>
                          <div
                            style={{ backgroundColor: colors.border3, height:110 }}
                            className="img-content text-center"
                          >
                            <span style={{}}>
                              <Link
                                to={
                                  process.env.PUBLIC_URL + "/instructor-details"
                                }
                              >
                                {data.personName}
                              </Link>
                            </span>
                            {/* <p>{data.personTitle}</p> */}
                            <ul style={{marginInline:5}} className="list-unstyled list-inline">
                              <li className="list-inline-item">
                                <a
                                  href={
                                    process.env.PUBLIC_URL +
                                    data.socialLinks.facebook
                                  }
                                >
                                  <i className="fab fa-facebook-f"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href={
                                    process.env.PUBLIC_URL +
                                    data.socialLinks.twitter
                                  }
                                >
                                  <i className="fab fa-twitter"></i>
                                </a>
                              </li>
                              <li className="list-inline-item">
                                <a
                                  href={
                                    process.env.PUBLIC_URL +
                                    data.socialLinks.youtube
                                  }
                                >
                                  <i className="fab fa-youtube"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Col>
                    ))}

                    {/* <Col md="12" className="text-center">
                      <Pagination />
                    </Col> */}
                  </Row>
                </Container>
              </section>

              {/* Footer 2 */}
              <FooterTwo />
            </div>
          </Styles>
        );
    }
}

export default Instructor